import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "studyShowView" }
const _hoisted_2 = { class: "anchorColumn" }
const _hoisted_3 = { class: "hotHeadLineViewLeft" }

import { ref } from "vue";
import StudyShowContentView from "@/views/studyShow/StudyShowContentView.vue";

interface slideshowInterface {
  id: number;
  name: string;
  image: string[];
  describe: string;
  term: string;
  echartsDataArray: Array<any>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyShowView',
  setup(__props) {

const slideshowObject = ref<slideshowInterface[]>([
  {
    id: 1,
    name: "陈同学",
    image: [
      require("@/assets/studyShow/11-21陈同学1.jpg"),
      require("@/assets/studyShow/11-21陈同学2.jpg"),
      require("@/assets/studyShow/11-21陈同学3.jpg"),
      require("@/assets/studyShow/11-21陈同学4.jpg"),
      require("@/assets/studyShow/11-21陈同学5.jpg"),
      require("@/assets/studyShow/11-21陈同学6.jpg"),
    ],
    describe: "24年11月21日实单分别为$520、$870、$1000、$2970",
    term: "二期线下班",
    echartsDataArray: [0, 520, 870, 1000, 2970],
  },
  {
    id: 2,
    name: "王同学",
    image: [
      require("@/assets/studyShow/1-4王同学1.jpg"),
      require("@/assets/studyShow/1-4王同学2.jpg"),
      require("@/assets/studyShow/1-4王同学3.jpg"),
      require("@/assets/studyShow/1-4王同学4.jpg"),
    ],
    describe: "24年11月24日实单$1814，1月3日实单$1080，1月4日实单$2120",
    term: "二期线下班",
    echartsDataArray: [0, 1814, 1814, 3200],
  },
  {
    id: 3,
    name: "刘同学",
    image: [
      require("@/assets/studyShow/2-18刘同学1.jpg"),
      require("@/assets/studyShow/2-18刘同学2.jpg"),
      require("@/assets/studyShow/2-18刘同学3.jpg"),
    ],
    describe: "2月18日实单$300",
    term: "四期线下班",
    echartsDataArray: [null, null, null, 0, 300],
  },
  {
    id: 4,
    name: "王同学",
    image: [
      require("@/assets/studyShow/2-18王同学1.jpg"),
      require("@/assets/studyShow/2-18王同学2.jpg"),
    ],
    describe: "2月18日实单$875",
    term: "四期线下班",
    echartsDataArray: [null, null, null, 0, 875],
  },
  {
    id: 5,
    name: "周同学",
    image: [
      require("@/assets/studyShow/2-10周同学.jpg"),
      require("@/assets/studyShow/2-14周同学.jpg"),
    ],
    describe: "2月10日实单$355, 2月14日实单$385",
    term: "四期线下班",
    echartsDataArray: [null, null, null, 0, 685],
  },
  {
    id: 6,
    name: "远同学",
    image: [
      require("@/assets/studyShow/12-26远同学1.jpg"),
      require("@/assets/studyShow/12-26远同学2.jpg"),
    ],
    describe: "12月26日实单$785~$990",
    term: "三期线下班",
    echartsDataArray: [null, 0, 785, 990],
  },
  {
    id: 7,
    name: "陈同学",
    image: [
      require("@/assets/studyShow/2-10陈同学1.jpg"),
      require("@/assets/studyShow/2-10陈同学2.jpg"),
    ],
    describe: "2月10日实单$1382~$2067",
    term: "四期线下班",
    echartsDataArray: [null, null, 0, 1382, 2067],
  },
  {
    id: 8,
    name: "宋同学",
    image: [
      require("@/assets/studyShow/2-14宋同学1.jpg"),
      require("@/assets/studyShow/2-14宋同学1.jpg"),
    ],
    describe: "2月14日实单$380",
    term: "四期线下班",
    echartsDataArray: [null, null, null, 0, 380],
  },
  {
    id: 9,
    name: "周同学",
    image: [
      require("@/assets/studyShow/1-9周同学1.jpg"),
      require("@/assets/studyShow/1-9周同学2.jpg"),
    ],
    describe: "12月16日实单$285, 1月9日实单$420",
    term: "三期线下班",
    echartsDataArray: [null, 0, 285, 420],
  },
  {
    id: 10,
    name: "胡同学",
    image: [require("@/assets/studyShow/11-21胡同学.jpg")],
    describe: "11月21日实单$1350",
    term: "二期线下班",
    echartsDataArray: [0, 1350],
  },
  {
    id: 11,
    name: "廖同学",
    image: [require("@/assets/studyShow/11-21廖同学.jpg")],
    describe: "11月21日实单$445",
    term: "二期线下班",
    echartsDataArray: [0, 445],
  },
  {
    id: 12,
    name: "陆同学",
    image: [require("@/assets/studyShow/11-21陆同学.jpg")],
    describe: "11月21日实单$740",
    term: "二期线下班",
    echartsDataArray: [0, 740],
  },
  {
    id: 13,
    name: "谭同学",
    image: [require("@/assets/studyShow/11-21谭同学.jpg")],
    describe: "11月21日实单$1250",
    term: "二期线下班",
    echartsDataArray: [0, 1250],
  },

  {
    id: 14,
    name: "姚同学",
    image: [require("@/assets/studyShow/1-17姚同学.jpg")],
    describe: "1月17日实单$2135",
    term: "二期线下班",
    echartsDataArray: [null, null, 0, 2135],
  },
  {
    id: 15,
    name: "耿同学",
    image: [require("@/assets/studyShow/1-9耿同学.jpg")],
    describe: "1月9日实单$2592",
    term: "三期线下班",
    echartsDataArray: [null, null, 0, 2592],
  },
  {
    id: 16,
    name: "辉同学",
    image: [require("@/assets/studyShow/1-21辉同学.jpg")],
    describe: "1月21日实单$1215",
    term: "二期线下班",
    echartsDataArray: [null, null, 0, 1215],
  },
  {
    id: 17,
    name: "郭同学",
    image: [require("@/assets/studyShow/12-27郭同学.jpg")],
    describe: "12月27日实单$1000",
    term: "三期线下班",
    echartsDataArray: [null, 0, 1000],
  },
  {
    id: 18,
    name: "韩同学",
    image: [require("@/assets/studyShow/12-26韩同学.jpg")],
    describe: "12月26日实单$495",
    term: "三期线下班",
    echartsDataArray: [null, 0, 495],
  },
  {
    id: 19,
    name: "何同学",
    image: [require("@/assets/studyShow/1-16何同学.jpg")],
    describe: "1月16日实单$1845",
    term: "三期线下班",
    echartsDataArray: [null, null, 0, 1845],
  },
  {
    id: 20,
    name: "梁同学",
    image: [require("@/assets/studyShow/1-9梁同学.jpg")],
    describe: "1月9日实单$3770",
    term: "三期线下班",
    echartsDataArray: [null, null, 0, 3770],
  },
  {
    id: 21,
    name: "陆同学",
    image: [require("@/assets/studyShow/12-16陆同学.jpg")],
    describe: "12月16日实单$815",
    term: "三期线下班",
    echartsDataArray: [null, 0, 815],
  },
  {
    id: 22,
    name: "王同学",
    image: [require("@/assets/studyShow/12-16王同学.jpg")],
    describe: "12月16日实单$1150",
    term: "三期线下班",
    echartsDataArray: [null, 0, 1150],
  },
  {
    id: 23,
    name: "肖同学",
    image: [require("@/assets/studyShow/12-27肖同学.jpg")],
    describe: "12月27日实单$590",
    term: "三期线下班",
    echartsDataArray: [null, 0, 590],
  },
  {
    id: 24,
    name: "赵同学",
    image: [require("@/assets/studyShow/1-16赵同学.jpg")],
    describe: "1月16日实单$1079",
    term: "三期线下班",
    echartsDataArray: [null, null, 0, 1079],
  },
  {
    id: 25,
    name: "范同学",
    image: [require("@/assets/studyShow/1-16范同学.jpg")],
    describe: "1月16日实单$445",
    term: "四期线下班",
    echartsDataArray: [null, null, 0, 445],
  },
  {
    id: 26,
    name: "凌同学",
    image: [require("@/assets/studyShow/2-14凌同学.jpg")],
    describe: "2月14日实单$385",
    term: "四期线下班",
    echartsDataArray: [null, null, null, 0, 385],
  },
  {
    id: 27,
    name: "知行同学",
    image: [require("@/assets/studyShow/2-20知行同学.jpg")],
    describe: "2月20日实单$870",
    term: "四期线下班",
    echartsDataArray: [null, null, null, 0, 870],
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_a_anchor_link = _resolveComponent("a-anchor-link")!
  const _component_a_anchor = _resolveComponent("a-anchor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_anchor, {
        direction: "horizontal",
        affix: false,
        onClick: _ctx.onChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slideshowObject.value, (item) => {
            return (_openBlock(), _createBlock(_component_a_anchor_link, {
              key: item.id,
              href: '#studyShowSlideshow-' + item.id,
              title: item.name
            }, null, 8, ["href", "title"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(StudyShowContentView, { "head-line-object": slideshowObject.value }, null, 8, ["head-line-object"])
    ])
  ]))
}
}

})