import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "merchantOneView" }
const _hoisted_2 = {
  class: "merchantBox",
  style: {"border-radius":"16px 0 0 0","height":"321px"}
}
const _hoisted_3 = {
  class: "merchantBox",
  style: {"border-radius":"0 0 0 16px","height":"321px"}
}
const _hoisted_4 = {
  class: "merchantBox",
  style: {"height":"140px"}
}
const _hoisted_5 = { class: "span" }
const _hoisted_6 = {
  class: "merchantBox",
  style: {"height":"240px"}
}
const _hoisted_7 = {
  class: "merchantBox",
  style: {"height":"240px"}
}
const _hoisted_8 = {
  class: "merchantBox",
  style: {"height":"504px"}
}
const _hoisted_9 = {
  class: "merchantBox",
  style: {"border-radius":"0 16px 16px 0","height":"100%"}
}

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MerchantOneView',
  setup(__props) {

//
const titleOne = ref("什么是做市商逻辑？");
const titleTwo = ref("为什么要学习做市商模式？");
const more = ref("了解详情");

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", null, "做市商逻辑", -1)),
    _createVNode(_component_a_row, {
      justify: "space-between",
      gutter: 24,
      class: "merchantRow"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 10 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, {
              gutter: [0, 24],
              wrap: "wrap"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("span", null, _toDisplayString(titleOne.value), 1),
                      _createVNode(_component_router_link, { to: "/merchantData" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(more.value), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _cache[0] || (_cache[0] = _createElementVNode("span", null, "做市商逻辑的价值？", -1)),
                      _createVNode(_component_router_link, { to: "/merchantData" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(more.value), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 10 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, {
              gutter: [0, 24],
              wrap: "wrap"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(titleOne.value), 1),
                      _createVNode(_component_router_link, { to: "/merchantData" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(more.value), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_row, { gutter: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, { span: 16 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_row, {
                              gutter: [0, 24],
                              wrap: "wrap"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, { span: 24 }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_6, [
                                      _createElementVNode("span", null, _toDisplayString(titleTwo.value), 1),
                                      _createVNode(_component_router_link, { to: "/merchantData" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(more.value), 1)
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_col, { span: 24 }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_7, [
                                      _createElementVNode("span", null, _toDisplayString(titleTwo.value), 1),
                                      _createVNode(_component_router_link, { to: "/merchantData" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(more.value), 1)
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_col, { span: 8 }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _cache[1] || (_cache[1] = _createElementVNode("span", { style: {"writing-mode":"vertical-lr","text-orientation":"upright","letter-spacing":"3px"} }, "做市商逻辑与订单流的区别", -1)),
                              _createVNode(_component_router_link, {
                                to: "/merchantData",
                                style: {"writing-mode":"vertical-rl","text-orientation":"upright","letter-spacing":"3px"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(more.value), 1)
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 4 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { style: {"writing-mode":"vertical-lr","text-orientation":"upright","letter-spacing":"3px"} }, "做市商逻辑与订单流分析的优劣", -1)),
              _createVNode(_component_router_link, {
                to: "/merchantData",
                style: {"writing-mode":"vertical-rl","text-orientation":"upright","letter-spacing":"3px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(more.value), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})