import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "merchantData" }
const _hoisted_2 = ["id"]

import CommonBreadcrumbComponent from "@/components/CommonBreadcrumbComponent.vue";
import { onMounted, ref } from "vue";

interface Route {
  path: string;
  breadcrumbName: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MerchantDataPage',
  setup(__props) {

const merchantDataObject = ref([
  {
    title: "什么是做市商逻辑",
  },
  {
    title: "订单流是什么？",
  },
  {
    title: "做市商逻辑的价值？",
  },
  {
    title: "为什么要学习做市商模式？",
  },
  {
    title: "做市商逻辑适合什么产品？",
  },
  {
    title: "做市商逻辑与订单流的区别？",
  },
  {
    title: "做市商逻辑与订单流分析的优势",
  },
]);

const merchantDataContent = ref(
  `做市商(Market Maker)是指在市场中持续提供买卖报价、维护市场流动性的一类市场参与者。做市商的主要职责是为特定金融产品(如股票、期货或外汇)提供双向报价，即同时发布买入价和卖出价，以确保市场中交易的顺畅进行。
  主要逻辑：做市商通过持续买卖来赚取买卖价差(Bid-AskSpread)，而不是靠市场的趋势获利。做市商的目标是通过在市场中持续进行交易，确保自己能从频繁的成交中获取稳定的利润。
  风险管理：做市商通过库存管理和对冲策略来规避风险。当做市商积累了较大的头寸时，他们会通过对冲操作或调整报价来减轻风险。
  市场流动性：做市商的作用主要在于为市场提供充足的流动性，使得买卖双方能够以更小的滑点完成交易。通过不断调整报价，做市商确保在市场缺乏流动性时，价格波动不会过于剧烈。
  行为特点：做市商通常不会跟随市场的价格趋势，而是通过观察市场中的供需情况调整自己的买卖报价，保持一定的中立性，以确保其库存的平衡。`
);

const dataTime = ref("2025-2-16 00:00:00");

const routes = ref<Route[]>([
  {
    path: "/home",
    breadcrumbName: "首页",
  },
  {
    //学员焦点
    path: "#homeElementEightView",
    breadcrumbName: "学员焦点",
  },
  {
    // 文章标题
    path: "",
    breadcrumbName: "articleTitle",
  },
]);

/*const targetOffset = ref<number | undefined>(undefined);
onMounted(() => {
  targetOffset.value = window.innerHeight / 4;
});*/

return (_ctx: any,_cache: any) => {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_anchor_link = _resolveComponent("a-anchor-link")!
  const _component_a_anchor = _resolveComponent("a-anchor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _createVNode(CommonBreadcrumbComponent, { routes: routes.value }, null, 8, ["routes"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(merchantDataObject.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            id: item.title,
            key: index
          }, [
            _createElementVNode("h1", null, _toDisplayString(item.title), 1),
            _createElementVNode("p", null, "时间：" + _toDisplayString(dataTime.value), 1),
            _createElementVNode("p", null, _toDisplayString(merchantDataContent.value), 1),
            _createVNode(_component_a_divider)
          ], 8, _hoisted_2))
        }), 128))
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_a_anchor, {
          "target-offset": _ctx.targetOffset,
          onClick: _ctx.onChange
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("p", null, "资料", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(merchantDataObject.value, (item, index) => {
              return (_openBlock(), _createBlock(_component_a_anchor_link, {
                key: index,
                title: item.title,
                href: '#' + item.title
              }, null, 8, ["title", "href"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["target-offset", "onClick"])
      ])
    ])
  ]))
}
}

})