<template>
  <div id="commentsSection">
    <!--评论区-->
    <a-list
      class="comment-list"
      :header="`${data.length} 条评论`"
      item-layout="horizontal"
      :data-source="data"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-comment :author="item.author">
            <template #content>
              <p>
                {{ item.content }}
              </p>
            </template>
            <template #datetime>
              <a-tooltip :title="item.datetime.format('YYYY-MM-DD HH:mm:ss')">
                <span>{{ item.datetime.fromNow() }}</span>
              </a-tooltip>
            </template>
          </a-comment>
        </a-list-item>
      </template>
    </a-list>
    <!--评论输入-->
    <a-list
      v-if="comments.length"
      :data-source="comments"
      :header="`${comments.length} ${
        comments.length > 1 ? 'replies' : 'reply'
      }`"
      item-layout="horizontal"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-comment
            :author="item.author"
            :avatar="item.avatar"
            :content="item.content"
            :datetime="item.datetime"
          />
        </a-list-item>
      </template>
    </a-list>
    <a-comment>
      <template #avatar>
        <a-avatar src="" alt="" />
      </template>
      <template #content>
        <a-form-item>
          <a-textarea v-model:value="value" :rows="4" />
        </a-form-item>
        <a-form-item>
          <a-button
            html-type="submit"
            :loading="submitting"
            type="primary"
            @click="handleSubmit"
          >
            添加评论
          </a-button>
        </a-form-item>
      </template>
    </a-comment>
  </div>
</template>
<script setup lang="ts">
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ref } from "vue";

dayjs.extend(relativeTime);

const data = [
  {
    actions: ["Reply to"],
    author: "易天循",
    avatar: "https://joeschmoe.io/api/v1/random",
    content:
      "全职交易者要去学发牌的庄家，而不是去学打牌的散户，你不但面临着久赌必输。还要时刻担心，那些做局的人。你要做的，就是去学会发牌，学会怎么去做局，搞清楚他们到底怎么弄的。",
    datetime: dayjs().subtract(1, "days"),
  },
  {
    actions: ["Reply to"],
    author: "易天循",
    avatar: "https://joeschmoe.io/api/v1/random",
    content:
      "现货黄金、MT4很多钱根本没流入市场，就像你去彩票店，老板给了你打了空单，你的钱根本没有进入市场，彩票店老板是在和你对赌。",
    datetime: dayjs().subtract(2, "days"),
  },
];

type Comment = Record<string, string>;

const comments = ref<Comment[]>([]);
const submitting = ref<boolean>(false);
const value = ref<string>("");
const handleSubmit = () => {
  if (!value.value) {
    return;
  }

  submitting.value = true;

  setTimeout(() => {
    submitting.value = false;
    comments.value = [
      {
        author: "Han Solo",
        avatar: "https://joeschmoe.io/api/v1/random",
        content: value.value,
        datetime: dayjs().fromNow(),
      },
      ...comments.value,
    ];
    value.value = "";
  }, 1000);
};
</script>

<style scoped></style>
