<template>
  <div id="videoSchoolHeadView">
    <div class="text">
      <h2>导师动态</h2>
      <h1>名师指导&nbsp;&nbsp;交易赋能</h1>
      <p>
        顶尖名师专业指导，量身打造交易策略与实战方案，解决您的疑惑<br />
        与难题，全面强化交易技巧与风控能力，助您轻松通过挑战，实现<br />
        自我突破，迈向精英交易者的顶峰。
      </p>
    </div>
    <div class="figureAll">
      <div class="figure">
        <img src="../../assets/images/yitianxun.jpg" alt="" />
        <div class="figure-name">
          <p>易天循</p>
          <p>yitianxun</p>
        </div>
      </div>
    </div>
    <div class="headView-invite">
      <p>成为PYTA全球合作导师，与顶尖交易名师携手，共创全球一流自营交易学院</p>
      <router-link to="#"><u>立即加入PYTA</u>→</router-link>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#videoSchoolHeadView {
  width: 1200px;
  height: auto;
  color: white;
  margin-top: 100px;
}

#videoSchoolHeadView .text {
  width: 100%;
  height: auto;
  color: white;
}

#videoSchoolHeadView .text h2 {
  font-size: 40px;
  line-height: 60px;
  text-align: left;
  margin-bottom: 16px;
}

#videoSchoolHeadView .text h1 {
  font-size: 64px;
  line-height: 75px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  text-align: left;
  margin-bottom: 24px;
}

#videoSchoolHeadView .text p {
  font-size: 20px;
  line-height: 23px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  text-align: left;
}

#videoSchoolHeadView .figureAll {
  width: 100%;
  height: auto;
  margin-top: 100px;
}

#videoSchoolHeadView .figure {
  width: 180px;
  height: 80px;
  border-radius: 40px;
  background-color: #1c346c;
  display: flex;
  padding: 10px;
  overflow: hidden;
}

#videoSchoolHeadView .figure img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

#videoSchoolHeadView .figure-name {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 16px;
  letter-spacing: 2px;
  margin-left: 10px;
}

#videoSchoolHeadView .headView-invite {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-top: 40px;
}

#videoSchoolHeadView .headView-invite a {
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #de2727;
}
</style>
