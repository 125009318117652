import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "softwareTwoView" }
const _hoisted_2 = { class: "box" }

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SoftwareTwoView',
  setup(__props) {

const describeObject = ref([
  {
    id: "01",
    describe: "下载并安装软件，打开XXX.exe获取您的机器码",
  },
  {
    id: "02",
    describe: "在下方填入机器码",
  },
  {
    id: "03",
    describe: "耐心等待后台系统激活，助您使用愉快",
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input_search = _resolveComponent("a-input-search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h1", null, "Jigsaw", -1)),
    _createVNode(_component_a_button, null, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode("购买软件")
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h2", null, "激活软件步骤", -1)),
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(describeObject.value, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id
          }, [
            _createElementVNode("h1", null, _toDisplayString(item.id), 1),
            _createElementVNode("p", null, _toDisplayString(item.describe), 1),
            (item.id == '02')
              ? (_openBlock(), _createBlock(_component_a_input_search, {
                  key: 0,
                  value: _ctx.value,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
                  placeholder: "请输入机器码",
                  size: "large",
                  onSearch: _ctx.onSearch
                }, {
                  enterButton: _withCtx(() => [
                    _createVNode(_component_a_button, null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("发送")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value", "onSearch"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})