import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "articleDetails" }
const _hoisted_2 = { class: "detailsHead" }
const _hoisted_3 = { class: "detailsSummarize" }
const _hoisted_4 = { class: "detailsText" }
const _hoisted_5 = { class: "detailsSupplement" }

import {
  QqCircleFilled,
  WeiboCircleFilled,
  WechatFilled,
  WarningFilled,
} from "@ant-design/icons-vue";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ArticleDetailsView',
  setup(__props) {

const content = ref(
  "全职交易者要去学发牌的庄家，而不是去学打牌的散户，你不但面临着久赌必输。还要时刻担心，那些做局的人。你要做的，就是去学会发牌，学会怎么去做局，搞清楚他们到底怎么弄的，那你得知道做市商赚钱的逻辑，对吧？你要去了解市场深度和盘口信息，他们通过这些实时的挂单、撤单和成交，去收割散户。你得参与到这场游戏当中，和发牌的人站在一起，而不是被动的去打牌，等着被收割，打牌的一次能赢、两次能赢、三次也能赢\n" +
    "那四次，五次呢？四次、五次能赢的，能继续一直赢的，一定就是做市商、机构和庄家。"
);

return (_ctx: any,_cache: any) => {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", null, "要做发牌的庄家，不做打牌的散户！", -1)),
      _createElementVNode("div", null, [
        _cache[1] || (_cache[1] = _createElementVNode("div", null, [
          _createElementVNode("p", null, "作者：李莉老师"),
          _createElementVNode("p", null, "时间：2025-1-18 08:00"),
          _createElementVNode("p", null, "来源：今日头条")
        ], -1)),
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "分享", -1)),
          _createVNode(_unref(QqCircleFilled), { style: {"color":"#fff2e4","background-color":"#faad08","border-radius":"50%"} }),
          _createVNode(_unref(WeiboCircleFilled), { style: {"color":"#ffe8e4","background-color":"#e54f00","border-radius":"50%"} }),
          _createVNode(_unref(WechatFilled), { style: {"color":"#2aae67"} })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_typography_paragraph, {
        ellipsis: _ctx.ellipsis ? { height: '12px' } : {}
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("span", null, "概述：", -1)),
          _createTextVNode(_toDisplayString(content.value), 1)
        ]),
        _: 1
      }, 8, ["ellipsis"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, _toDisplayString(content.value), 1)
    ]),
    _createVNode(_component_a_divider, { style: {"height":"1px","background-color":"#ebebeb"} }),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", null, [
        _createVNode(_unref(WarningFilled), { style: {"color":"#ed0e0e"} }),
        _cache[4] || (_cache[4] = _createTextVNode(" 风险提示及免责条款：市场有风险，投资需谨慎。本文不构成个人投资建议，也未考虑到个别用户特殊的投资目标、财务状况或需要。用户应考虑本文中的任何意见、观点或结论是否符合其特定状况。据此投资，责任自负。 "))
      ])
    ])
  ]))
}
}

})