import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../assets/images/gold.png'


const _hoisted_1 = { id: "videoSchoolElementTwo" }
const _hoisted_2 = { class: "anchorColumn" }
const _hoisted_3 = { class: "invitation-register" }
const _hoisted_4 = { class: "videSlideshow" }

import VideoSchoolTwoSlideshow from "@/views/videoSchoolView/VideoSchoolTwoSlideshow.vue";
import { ref } from "vue";

interface slideshowInterface {
  id: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoSchoolElementTwoView',
  setup(__props) {

const slideshowObject = ref<slideshowInterface[]>([
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
]);

return (_ctx: any,_cache: any) => {
  const _component_a_anchor_link = _resolveComponent("a-anchor-link")!
  const _component_a_anchor = _resolveComponent("a-anchor")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_anchor, {
        direction: "horizontal",
        affix: false,
        onClick: _ctx.onChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slideshowObject.value, (item) => {
            return (_openBlock(), _createBlock(_component_a_anchor_link, {
              key: item.id,
              href: '#videoSchoolTwoSlideshow-' + item.id,
              title: '易天循实战系列' + item.id
            }, null, 8, ["href", "title"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", null, [
        _createElementVNode("h1", null, "注册用户"),
        _createElementVNode("h1", null, "免费观看部分干货视频")
      ], -1)),
      _createVNode(_component_a_button, null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Go")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(VideoSchoolTwoSlideshow, { "slideshow-object": slideshowObject.value }, null, 8, ["slideshow-object"])
    ])
  ]))
}
}

})