<!-- 学员欢迎礼 邀请注册 -->
<template>
  <div id="invitation-register">
    <img src="/public/images/crown.png" alt="" />
    <div>
      <div>
        <h1>新学员欢迎礼</h1>
        <p>注册即可获得vip专属学习资料及导师视频</p>
      </div>
      <a-button>立即开通</a-button>
    </div>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#invitation-register {
  width: 1200px;
  height: 180px;
  background-image: url("../assets/images/invitation_register_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  color: white;
  padding-right: 60px;
}

#invitation-register > div {
  width: 950px;
  height: 103px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#invitation-register > div > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

:deep(.ant-btn) {
  color: white;
  font-size: 16px;
  width: 196px;
  height: 50px;
  background-color: #de2727;
  border: none;
}

#invitation-register h1 {
  font-size: 48px;
  line-height: 56px;
  text-align: left;
}

#invitation-register p {
  font-size: 20px;
  line-height: 23px;
  text-align: left;
}
</style>
