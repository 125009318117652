<template>
  <div id="homeElementTwoView">
    <h1>天循老师实盘做单记录</h1>
    <div>
      <video controls>
        <source
          src="../../assets/video/video_landscape2-2.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <router-link to="/videoList"><u>更多视频</u>→</router-link>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#homeElementTwoView {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#homeElementTwoView h1 {
  width: 790px;
  height: 72px;
  color: #2d211c;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
}

#homeElementTwoView div {
  width: 100%;
  height: 674px;
  margin: 50px 0;
}

#homeElementTwoView div video {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#homeElementTwoView a {
  height: 22px;
  font-size: 16px;
  color: #de2727;
  line-height: 19px;
  text-align: right;
}
</style>
