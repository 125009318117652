import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "slideshow-title" }
const _hoisted_3 = { class: "slideshow" }
const _hoisted_4 = {
  class: "custom-slick-arrow",
  style: {"left":"10px","z-index":"1"}
}
const _hoisted_5 = {
  class: "custom-slick-arrow",
  style: {"right":"10px"}
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "slideshowDiv"
}
const _hoisted_8 = {
  key: 1,
  class: "slideshowDiv"
}
const _hoisted_9 = {
  key: 2,
  class: "slideshowDiv"
}

import {
  LeftCircleOutlined,
  RightCircleOutlined,
  ShoppingCartOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons-vue";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'VideoSchoolTwoSlideshow',
  props: {
  slideshowObject: Object,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_carousel = _resolveComponent("a-carousel")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.slideshowObject, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      key: item.id,
      id: 'videoSchoolTwoSlideshow-' + item.id,
      style: {"scroll-padding-top":"80px"}
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("h1", null, "易天循" + _toDisplayString(item.describe) + "实盘记录", 1),
          _createElementVNode("p", null, [
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "本系列课程包含：", -1)),
            _createTextVNode(_toDisplayString(item.videoArray.length) + "个视频", 1)
          ])
        ]),
        _createVNode(_component_a_button, { style: {"display":"none"} }, {
          default: _withCtx(() => [
            _createVNode(_unref(ShoppingCartOutlined), { style: {"color":"white","margin":"0"} }),
            _cache[1] || (_cache[1] = _createElementVNode("span", null, "购买整套课程", -1)),
            _createVNode(_unref(MoneyCollectOutlined), { style: {"color":"white","margin":"0"} }),
            _cache[2] || (_cache[2] = _createElementVNode("strong", { style: {"font-size":"36px","margin-left":"8px"} }, "500", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("del", { style: {"font-size":"24px","margin-left":"8px"} }, "800", -1))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_carousel, {
          arrows: "",
          dots: false,
          "slides-to-show": 4
        }, {
          prevArrow: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(LeftCircleOutlined))
            ])
          ]),
          nextArrow: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(RightCircleOutlined))
            ])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.videoArray, (unitVideo, index) => {
              return (_openBlock(), _createElementBlock("video", {
                controls: "controls",
                key: index
              }, [
                _createElementVNode("source", {
                  src: unitVideo,
                  type: "video/mp4"
                }, null, 8, _hoisted_6)
              ]))
            }), 128)),
            (item.videoArray.length < 4)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7))
              : _createCommentVNode("", true),
            (item.videoArray.length < 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8))
              : _createCommentVNode("", true),
            (item.videoArray.length < 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_a_divider)
      ])
    ], 8, _hoisted_1))
  }), 128))
}
}

})