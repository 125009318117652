import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../assets/images/成绩单.jpg'


const _hoisted_1 = { id: "introduceElementThree" }
const _hoisted_2 = { class: "live" }
const _hoisted_3 = { class: "reportCard" }
const _hoisted_4 = { class: "reportCardShow" }

import CommonLiveLessonComponent from "@/components/CommonLiveLessonComponent.vue";

const inspirationalPhrase = "沉着、冷静、勇猛、有辨别、不自私。 --鲁迅";

export default /*@__PURE__*/_defineComponent({
  __name: 'IntroduceElementThreeView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", null, [
        _createElementVNode("h1", null, "名师直播课"),
        _createElementVNode("a", {
          href: "",
          target: "_blank"
        }, [
          _createElementVNode("u", null, "查看更多"),
          _createTextVNode("→")
        ])
      ], -1)),
      _createVNode(CommonLiveLessonComponent)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[2] || (_cache[2] = _createElementVNode("div", null, [
        _createElementVNode("h1", null, "学员成绩单展示"),
        _createElementVNode("a", {
          href: "",
          target: "_blank"
        }, [
          _createElementVNode("u", null, "查看更多"),
          _createTextVNode("→")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
          return _createElementVNode("div", { key: i }, [
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1)),
            _createElementVNode("p", null, _toDisplayString(inspirationalPhrase))
          ])
        }), 64))
      ])
    ])
  ]))
}
}

})