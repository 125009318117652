import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../public/logo.png'


const _hoisted_1 = {
  class: "commonGlobalHeader-buttons",
  style: {"display":"none"}
}

import { onMounted, ref } from "vue";
import { MenuProps } from "ant-design-vue";
import { useRouter } from "vue-router";
import { message, ItemType } from "ant-design-vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonGlobalHeader',
  setup(__props) {

const error = () => {
  message.error("抱歉！该功能暂未开放");
};

/*获取路由跳转器*/
const router = useRouter();
/*路由跳转实现*/
const doMenuClick = ({ key }: { key: string }) => {
  router.push({ path: key });
};

const current = ref<string[]>(["home"]);
/*监听路由变化，更新选中菜单的状态*/
router.afterEach((to, from, next) => {
  current.value = [to.path];
});

const navigationBarRef = ref(null);

onMounted(() => {
  // 等待组件挂载后再访问 ref
  setTimeout(() => {
    if (navigationBarRef.value) {
      navigationBarRef.value.scrollIntoView({ behavior: "smooth" });
    }
  }, 0);
});

router.afterEach((to, from) => {
  // 等待路由完全过渡后再滚动（可选，可获得更流畅的体验）
  setTimeout(() => {
    if (navigationBarRef.value) {
      navigationBarRef.value.scrollIntoView({ behavior: "smooth" });
    }
  }, 100); // 根据需要调整延迟
});

function getItem(
  key: string,
  label: any,
  title: string,
  children?: ItemType[],
  type?: "group"
): ItemType {
  return {
    key,
    title,
    children,
    label,
    type,
  } as ItemType;
}

const items = ref<ItemType[]>([
  getItem("/", "首页", "首页"),
  getItem("/tutorDynamics", "导师动态", "导师动态"),
  getItem("/studyShow", "学员风采", "学员风采"),
  /*getItem("/pyta", "Pyta", "Pyta"),
  getItem("/videoSchool", "视频学院", "视频学院"),
  getItem(
    "/hotNews/hot",
    "热点新闻",
    "热点新闻"
    /!*    [
        getItem("/hotNews/news", "红点新闻", "红点新闻"),
        getItem("/hotNews/hot", "热点头条", "热点头条"),]*!/
  ),
  getItem("/transactionTraining", "交易培训", "交易培训", [
    getItem("/transactionTraining/live", "直播", "直播"),
    getItem("/transactionTraining/software", "交易软件", "交易软件"),
    getItem("/transactionTraining/merchant", "市商逻辑", "市商逻辑"),
  ]),
  getItem("/targetCode", "指标代码", "指标代码"),
  getItem("/aboutMe", "关于我们", "关于我们"),
  getItem("/vip", "VIP会员", "VIP会员"),*/
]);

return (_ctx: any,_cache: any) => {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", {
    id: "commonGlobalHeader",
    ref_key: "navigationBarRef",
    ref: navigationBarRef
  }, [
    _createVNode(_component_a_row, { class: "commonGlobalHeader-commonRow" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { flex: "60px" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "commonGlobalHeader-logo" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "logo"
              })
            ], -1)
          ])),
          _: 1
        }),
        _createVNode(_component_a_col, { flex: "auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_menu, {
              selectedKeys: current.value,
              "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((current).value = $event)),
              mode: "horizontal",
              items: items.value,
              onClick: doMenuClick
            }, null, 8, ["selectedKeys", "items"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { flex: "200px" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_a_button, {
                ghost: "",
                style: {"color":"white","border":"none"},
                size: "large",
                onClick: error
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("登录 ")
                ])),
                _: 1
              }),
              _createVNode(_component_a_button, {
                type: "primary",
                style: {"color":"white","background-color":"#e43830","border":"none"},
                size: "large",
                onClick: error
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("免费注册 ")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 512))
}
}

})