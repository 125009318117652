import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "homeElementSixView" }
const _hoisted_2 = ["src"]

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeElementSixView',
  setup(__props) {

const sixObject = ref([
  {
    peopleName: "陈同学",
    imageUrl: require("@/assets/studyShow/2-10陈同学1.jpg"),
    toLink: "/studyShow#studyShowSlideshow-7",
  },
  {
    peopleName: "王同学",
    imageUrl: require("@/assets/studyShow/12-16王同学.jpg"),
    toLink: "/studyShow#studyShowSlideshow-22",
  },
  {
    peopleName: "刘同学",
    imageUrl: require("@/assets/studyShow/2-18刘同学1.jpg"),
    toLink: "/studyShow#studyShowSlideshow-3",
  },
  {
    peopleName: "周同学",
    imageUrl: require("@/assets/studyShow/2-10周同学.jpg"),
    toLink: "/studyShow#studyShowSlideshow-9",
  },
  {
    peopleName: "宋同学",
    imageUrl: require("@/assets/studyShow/2-14宋同学1.jpg"),
    toLink: "/studyShow#studyShowSlideshow-5",
  },
  {
    peopleName: "胡同学",
    imageUrl: require("@/assets/studyShow/11-21胡同学.jpg"),
    toLink: "/studyShow#studyShowSlideshow-10",
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "看看线上学员的成绩", -1)),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sixObject.value, (item, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("img", {
            src: item.imageUrl,
            alt: ""
          }, null, 8, _hoisted_2),
          _createElementVNode("p", null, _toDisplayString(item.peopleName), 1)
        ]))
      }), 128))
    ])
  ]))
}
}

})