import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "aboutMePage" }
const _hoisted_2 = { class: "aboutMeHeadShow" }
const _hoisted_3 = { class: "elementOne" }
const _hoisted_4 = { class: "elementTwo" }
const _hoisted_5 = { class: "elementFour" }

import AboutMeHeadShowView from "@/views/aboutMe/AboutMeHeadShowView.vue";
import IntroduceElementOneView from "@/views/introduceView/IntroduceElementOneView.vue";
import AboutMeElementTwoView from "@/views/aboutMe/AboutMeElementTwoView.vue";
import AboutMeElementFourView from "@/views/aboutMe/AboutMeElementFourView.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'AboutMePage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(AboutMeHeadShowView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(IntroduceElementOneView)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(AboutMeElementTwoView)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "elementThree" }, [
      _createElementVNode("p", null, "线下课堂记录")
    ], -1)),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(AboutMeElementFourView)
    ])
  ]))
}
}

})