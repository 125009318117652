import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "hotHeadLineView" }
const _hoisted_2 = { class: "hotHeadLineViewLeft" }
const _hoisted_3 = { class: "hotHeadLineViewRight" }

import CommonVideoRecommendationComponent from "@/components/CommonVideoRecommendationComponent.vue";
import CommonHotHeadlineComponent from "@/components/CommonHotHeadlineComponent.vue";
import { ref } from "vue";

interface HeadLineObjects {
  id: number;
  headLineTitle: string;
  headLineContent: string;
  imageUrl: string;
  headLineTime: string;
  headLineSource: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HotHeadLineView',
  setup(__props) {

const headLineObject = ref<HeadLineObjects[]>([
  {
    id: 0,
    headLineTitle:
      "经济学家高善文：25-59岁人口失业率创新低，简单讨论失业率非常不完整",
    headLineContent:
      "在核污染水处置问题上，日方应正视国际社会的合理关切，本着对全球海洋环境和公众健康负责任的态度，同包括周边邻国在内的利益攸关方开展充分、有意义的协商。日方继续强推核污染水排海，只会激起更强烈的反对。",
    imageUrl: "../../assets/images/people.jpg",
    headLineTime: "2025-1-18 18:00:00",
    headLineSource: "今日头条",
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(CommonHotHeadlineComponent, { "head-line-object": headLineObject.value }, null, 8, ["head-line-object"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(CommonVideoRecommendationComponent)
    ])
  ]))
}
}

})