<template>
  <div id="nonFarmHeadShowView">
    <h1>实时数据&nbsp;快人一步</h1>
    <p>
      秒级更新非农数据，助你掌握最精准、透明的市场数据，让您做出最明智的交易决策和将风险最小化。
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

#nonFarmHeadShowView {
  width: 800px;
  height: 120px;
  text-align: center;
  color: white;
}

#nonFarmHeadShowView h1 {
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 4px;
  text-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

#nonFarmHeadShowView p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  text-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  margin: 0 181px;
}
</style>
