<!-- 更多内容 -->
<template>
  <div id="moreContent">
    <div>
      <p>邀请好友赚不停！</p>
      <p>邀请开课即赚XXX，成功邀请好友奖励无上限！</p>
      <a-button>了解更多</a-button>
    </div>
    <img src="../assets/images/more_content_image.png" alt="" />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#moreContent {
  background: linear-gradient(274deg, #d31a1a 0%, #ec4848 100%);
  border-radius: 24px;
  width: 1200px;
  height: 300px;
  color: white;
  display: flex;
  justify-content: space-between;
}

#moreContent div {
  width: auto;
  height: auto;
  margin: 52px 0 0 140px;
}

#moreContent img {
  width: 360px;
  height: 240px;
  margin: 30px 140px 0 0;
}

#moreContent div p:first-child {
  font-size: 56px;
  line-height: 66px;
  text-align: left;
}

#moreContent div p:nth-child(2) {
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 32px;
}

#moreContent div :deep(.ant-btn) {
  width: 196px;
  height: 50px;
  font-size: 20px;
  color: #de2727;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
</style>
