import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '../assets/images/people.jpg'


const _hoisted_1 = { id: "commonHotHeadLineComponent" }
const _hoisted_2 = { key: 0 }

import { ref } from "vue";

interface Route {
  path: string;
  breadcrumbName: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonHotHeadlineComponent',
  props: {
  headLineObject: Object,
},
  setup(__props) {

const routes = ref<Route[]>([
  {
    path: "/home",
    breadcrumbName: "首页",
  },
  /*  {
      path: "/hotNews",
      breadcrumbName: "热点新闻",
    },*/
  {
    path: "/news",
    breadcrumbName: "热点头条",
  },
]);

// eslint-disable-next-line no-undef


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_breadcrumb, { routes: routes.value }, {
      itemRender: _withCtx(({ route, paths }) => [
        (routes.value.indexOf(route) === routes.value.length - 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(route.breadcrumbName), 1))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: `/${paths.join('/')}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(route.breadcrumbName), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
      ]),
      _: 1
    }, 8, ["routes"]),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.headLineObject, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            class: "headLineContentBox"
          }, [
            _cache[0] || (_cache[0] = _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1)),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, { to: "/articleContent" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.headLineTitle), 1)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("p", null, _toDisplayString(item.headLineContent), 1)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(item.headLineTime), 1),
                _createElementVNode("span", null, "来源：" + _toDisplayString(item.headLineSource), 1)
              ])
            ])
          ]))
        }), 128)),
        (i != 10)
          ? (_openBlock(), _createBlock(_component_a_divider, { key: 0 }))
          : _createCommentVNode("", true)
      ], 64))
    }), 64))
  ]))
}
}

})