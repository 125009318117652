<template>
  <div id="softwareTwoView">
    <h1>Jigsaw</h1>
    <a-button>购买软件</a-button>
    <div class="box">
      <h2>激活软件步骤</h2>
      <div>
        <div v-for="item in describeObject" v-bind:key="item.id">
          <h1>{{ item.id }}</h1>
          <p>{{ item.describe }}</p>
          <a-input-search
            v-model:value="value"
            placeholder="请输入机器码"
            size="large"
            @search="onSearch"
            v-if="item.id == '02'"
          >
            <template #enterButton>
              <a-button>发送</a-button>
            </template>
          </a-input-search>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const describeObject = ref([
  {
    id: "01",
    describe: "下载并安装软件，打开XXX.exe获取您的机器码",
  },
  {
    id: "02",
    describe: "在下方填入机器码",
  },
  {
    id: "03",
    describe: "耐心等待后台系统激活，助您使用愉快",
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#softwareTwoView {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#softwareTwoView > h1 {
  font-size: 48px;
  text-align: center;
  line-height: 72px;
}

#softwareTwoView > :deep(.ant-btn) {
  width: 200px;
  height: 42px;
  background-color: #de2727;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 16px;
  margin: 20px 0 60px 0;
}

.box {
  width: 100%;
  height: 361px;
  background-color: white;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.box > h2 {
  font-size: 32px;
  text-align: center;
  line-height: 38px;
}

.box > div {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 33px;
}

.box > div div {
  width: 280px;
}

.box > div h1 {
  font-size: 32px;
  color: #de2727;
  line-height: 38px;
  text-align: left;
}

.box > div p {
  font-size: 20px;
  color: #2d211c;
  line-height: 23px;
  text-align: left;
  margin: 24px 0;
}
</style>
