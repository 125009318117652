<template>
  <div id="homeElementSixView">
    <h1>看看线上学员的成绩</h1>
    <div>
      <div v-for="(item, index) in sixObject" :key="index">
        <img :src="item.imageUrl" alt="" />
        <!--<router-link :to="item.toLink">{{ item.peopleName }} </router-link>-->
        <p>{{ item.peopleName }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const sixObject = ref([
  {
    peopleName: "陈同学",
    imageUrl: require("@/assets/studyShow/2-10陈同学1.jpg"),
    toLink: "/studyShow#studyShowSlideshow-7",
  },
  {
    peopleName: "王同学",
    imageUrl: require("@/assets/studyShow/12-16王同学.jpg"),
    toLink: "/studyShow#studyShowSlideshow-22",
  },
  {
    peopleName: "刘同学",
    imageUrl: require("@/assets/studyShow/2-18刘同学1.jpg"),
    toLink: "/studyShow#studyShowSlideshow-3",
  },
  {
    peopleName: "周同学",
    imageUrl: require("@/assets/studyShow/2-10周同学.jpg"),
    toLink: "/studyShow#studyShowSlideshow-9",
  },
  {
    peopleName: "宋同学",
    imageUrl: require("@/assets/studyShow/2-14宋同学1.jpg"),
    toLink: "/studyShow#studyShowSlideshow-5",
  },
  {
    peopleName: "胡同学",
    imageUrl: require("@/assets/studyShow/11-21胡同学.jpg"),
    toLink: "/studyShow#studyShowSlideshow-10",
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#homeElementSixView {
  width: 1200px;
  height: auto;
}

#homeElementSixView h1 {
  font-size: 48px;
  line-height: 72px;
  color: #2d211c;
  text-align: center;
  margin-bottom: 50px;
}

#homeElementSixView > div {
  width: 100%;
  height: 538px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

#homeElementSixView > div div {
  width: 379px;
  height: 253px;
  background-color: white;
  border-radius: 24px;
  border: 1px solid #ebebeb;
  overflow: hidden;
}

#homeElementSixView img {
  width: 100%;
  height: 187px;
  object-fit: cover;
}

#homeElementSixView p {
  height: 22px;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #2d211c;
  margin: 20px 24px;
}
</style>
