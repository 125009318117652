import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "hotNewsContentView" }
const _hoisted_2 = { class: "hotNewsContentViewLeft" }
const _hoisted_3 = { class: "hotNewsContentViewRight" }

import CommonNewsComponent from "@/components/CommonNewsComponent.vue";
import { ref } from "vue";
import CommonVideoRecommendationComponent from "@/components/CommonVideoRecommendationComponent.vue";

interface ContentObjects {
  id: number;
  time: string;
  content: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HotNewsContentView',
  setup(__props) {

const contentObject = ref<ContentObjects[]>([
  {
    id: 1,
    time: "21:21:21",
    content:
      "股市资金流向图：今日沪深两市主力资金净流出207.47亿元，占比2.15%；大单资金净流出120.10亿元，占比1.24%；小单资金净流入243.72亿元，占比2.52%。",
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(CommonNewsComponent, { "content-object": contentObject.value }, null, 8, ["content-object"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(CommonVideoRecommendationComponent)
    ])
  ]))
}
}

})