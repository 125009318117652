import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "tutorDynamics" }
const _hoisted_2 = { class: "tutorDynamicsHeadShow" }
const _hoisted_3 = { class: "elementOne" }
const _hoisted_4 = { class: "elementTwo" }

import VideoSchoolHeadView from "@/views/videoSchoolView/VideoSchoolHeadView.vue";
import TutorDynamicsOneView from "@/views/tutorDynamics/TutorDynamicsOneView.vue";
import TutorDynamicsTwoView from "@/views/tutorDynamics/TutorDynamicsTwoView.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'TutorDynamicsPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(VideoSchoolHeadView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(TutorDynamicsOneView)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(TutorDynamicsTwoView)
    ])
  ]))
}
}

})