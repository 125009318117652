import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/video/1月2日-MQ-01.mp4'


const _hoisted_1 = { id: "videoDetailsChildren" }
const _hoisted_2 = { class: "detailsHead" }
const _hoisted_3 = { class: "detailsSummarize" }
const _hoisted_4 = { class: "detailsSupplement" }

import {
  QqCircleFilled,
  WeiboCircleFilled,
  WechatFilled,
  WarningFilled,
} from "@ant-design/icons-vue";
import { ref } from "vue";
import CommonCommentsSectionComponent from "@/components/CommonCommentsSectionComponent.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'VideoDetailsChildrenView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", null, "要做发牌的庄家，不做打牌的散户！", -1)),
      _createElementVNode("div", null, [
        _cache[1] || (_cache[1] = _createElementVNode("div", null, [
          _createElementVNode("p", null, "作者：李莉老师"),
          _createElementVNode("p", null, "时间：2025-1-18 08:00"),
          _createElementVNode("p", null, "来源：今日头条")
        ], -1)),
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "分享", -1)),
          _createVNode(_unref(QqCircleFilled), { style: {"color":"#fff2e4","background-color":"#faad08","border-radius":"50%"} }),
          _createVNode(_unref(WeiboCircleFilled), { style: {"color":"#ffe8e4","background-color":"#e54f00","border-radius":"50%"} }),
          _createVNode(_unref(WechatFilled), { style: {"color":"#2aae67"} })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_typography_paragraph, {
        ellipsis: _ctx.ellipsis ? { height: '12px' } : {}
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("span", null, "概述：", -1)),
          _createTextVNode(_toDisplayString(_ctx.content), 1)
        ]),
        _: 1
      }, 8, ["ellipsis"])
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "detailsVideo" }, [
      _createElementVNode("video", { controls: "" }, [
        _createElementVNode("source", {
          src: _imports_0,
          type: "video/mp4"
        })
      ])
    ], -1)),
    _createVNode(_component_a_divider, { style: {"height":"1px","background-color":"#ebebeb"} }),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, [
        _createVNode(_unref(WarningFilled), { style: {"color":"#ed0e0e"} }),
        _cache[4] || (_cache[4] = _createTextVNode(" 风险提示及免责条款：市场有风险，投资需谨慎。本文不构成个人投资建议，也未考虑到个别用户特殊的投资目标、财务状况或需要。用户应考虑本文中的任何意见、观点或结论是否符合其特定状况。据此投资，责任自负。 "))
      ])
    ]),
    _createVNode(CommonCommentsSectionComponent)
  ]))
}
}

})