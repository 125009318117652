<template>
  <div id="tutorDynamicsOne">
    <div class="title">
      <img src="../../assets/images/yitianxun.jpg" alt="" />
      <h1>天循老师最新实盘做单记录</h1>
    </div>
    <div class="video">
      <video controls="controls">
        <source
          src="../../assets/video/video_landscape2-2.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <div class="invitation">
      <p>成为Pyta学员，与顶尖交易名师携手，实现长期稳定的盈利</p>
      <router-link to="/videoList"><u>更多视频</u>→</router-link>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#tutorDynamicsOne {
  width: 1294px;
  height: 690px;
  background-image: url("../../assets/images/video_school_bgImage.png");
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#tutorDynamicsOne .title {
  width: 1000px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 44px;
  overflow: hidden;
  padding: 0 108px;
}

#tutorDynamicsOne .title img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

#tutorDynamicsOne .title h1 {
  font-size: 48px;
  color: white;
  line-height: 72px;
  text-align: center;
}

#tutorDynamicsOne .video {
  width: 1200px;
  height: 408px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#tutorDynamicsOne .video video {
  width: 80%;
  height: 100%;
  border-radius: 24px;
  cursor: pointer;
}

#tutorDynamicsOne .invitation {
  width: 1200px;
  height: 70px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-top: 41px;
}

#tutorDynamicsOne .invitation a {
  color: white;
}
</style>
