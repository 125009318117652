<template>
  <div id="homeElementOne">
    <h1>PYTA自营交易学院</h1>
    <div>
      <div>
        <h2>聚焦实盘，实战为先</h2>
        <p>
          手把手教你运用做市商逻辑和机构交易方式帮助你获得与大资金更接近的视角和思维方式。<br />
          一人一法，提升你的交易决策能力、风险控制水平，并在市场中更游刃有余地参与。
        </p>
        <router-link to="/studyShow"><u>学员风采</u>→</router-link>
      </div>
      <img src="../../assets/images/home_image1.png" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#homeElementOne {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
}

#homeElementOne h1 {
  font-size: 48px;
  color: #2d211c;
  line-height: 72px;
  text-align: center;
  margin-bottom: 50px;
}

#homeElementOne > div {
  width: 100%;
  height: 502px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

#homeElementOne > div > div {
  padding: 24px 0;
}

#homeElementOne > div > div h2 {
  width: 276px;
  height: 34px;
  font-size: 24px;
  color: #2d211c;
  line-height: 28px;
  text-align: left;
  margin-bottom: 16px;
}

#homeElementOne > div > div p {
  width: 276px;
  font-size: 16px;
  color: #675954;
  line-height: 32px;
  text-align: left;
  margin-bottom: 180px;
}

#homeElementOne > div > div a {
  font-size: 16px;
  color: #de2727;
  line-height: 19px;
  float: right;
}

#homeElementOne > div img {
  width: 892px;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}
</style>
