import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/images/software_people_computer.png'


const _hoisted_1 = { id: "softwareHeadView" }
const _hoisted_2 = { class: "button" }

import { DownOutlined } from "@ant-design/icons-vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'SoftwareHeadView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _cache[7] || (_cache[7] = _createElementVNode("h1", null, "高端交易软件", -1)),
      _cache[8] || (_cache[8] = _createElementVNode("h1", null, "无延迟易上手", -1)),
      _cache[9] || (_cache[9] = _createElementVNode("p", null, [
        _createTextVNode(" Jigsaw帮助交易者简单、智能的进行交易，"),
        _createElementVNode("br"),
        _createTextVNode("被海内外大多数从业者评选为最好的交易软件 ")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_button, { class: "button-one" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("开通VIP")
          ])),
          _: 1
        }),
        _createVNode(_component_a_button, { class: "button-two" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("获取软件")
          ])),
          _: 1
        }),
        _createVNode(_component_a_dropdown, { trigger: ['click'] }, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, { key: "" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("a", { href: "" }, "1.3.1", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, { key: "" }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createElementVNode("a", { href: "" }, "1.2.6", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, { key: "" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createElementVNode("a", { href: "" }, "1.0.1", -1)
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: "ant-dropdown-link",
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _cache[3] || (_cache[3] = _createTextVNode(" 其他版本下载 ")),
              _createVNode(_unref(DownOutlined))
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("img", {
      src: _imports_0,
      alt: ""
    }, null, -1))
  ]))
}
}

})