<template>
  <div id="headShow">
    <h1>欢迎来到</h1>
    <h1>PYTA自营交易学院</h1>
    <h1>场内交易个性化培训专家</h1>
    <p>
      我们不仅专注于自营交易业务，还通过专业的培训服务，帮助全职交易员构建稳健的交易系统，实现长期稳定的盈利
    </p>
    <a-button>了解PYTA</a-button>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#headShow {
  width: 1200px;
  height: auto;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#headShow h1 {
  font-size: 64px;
  line-height: 96px;
  text-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
  text-align: center;
}

#headShow p {
  width: 822px;
  font-size: 20px;
  line-height: 30px;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
  text-align: center;
  margin-top: 16px;
  margin-bottom: 48px;
}

#headShow :deep(.ant-btn) {
  width: 200px;
  height: 42px;
  color: white;
  background-color: #de2727;
  border: none;
  font-size: 16px;
  border-radius: 8px;
}
</style>
