<!-- 红点新闻 -->
<template>
  <div id="newComponent">
    <!-- 面包屑 -->
    <a-breadcrumb :routes="routes">
      <template #itemRender="{ route, paths }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ route.breadcrumbName }}
        </span>
        <router-link v-else :to="`/${paths.join('/')}`">
          {{ route.breadcrumbName }}
        </router-link>
      </template>
    </a-breadcrumb>
    <!-- 时间选择框 -->
    <a-date-picker v-model:value="time" :format="dateFormat" />
    <!-- 内容 -->
    <template v-for="i in 10" :key="i">
      <div
        class="newComponentContent"
        v-for="item in contentObject"
        :key="item.id"
      >
        <p>{{ item.time }}</p>
        <router-link to="/articleContent">{{ item.content }}</router-link>
        <!-- 分割线 -->
        <a-divider v-if="i != 10" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import dayjs, { Dayjs } from "dayjs";

const dateFormat = "YYYY-MM-DD";

const time = ref<Dayjs>(dayjs(new Date().getDay().toString(), dateFormat));

interface Route {
  path: string;
  breadcrumbName: string;
}

const routes = ref<Route[]>([
  {
    path: "/home",
    breadcrumbName: "首页",
  },
  {
    path: "/hotNews",
    breadcrumbName: "热点新闻",
  },
  {
    path: "/news",
    breadcrumbName: "红点新闻",
  },
]);

// eslint-disable-next-line no-undef
defineProps({
  contentObject: Object,
});
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

#newComponent {
  width: 790px;
  height: auto;
}

:deep(.ant-breadcrumb) {
  font-size: 16px;
  height: 24px;
}

:deep(.ant-picker) {
  width: 200px;
  height: 38px;
  background-color: #f2f3f5;
  border-radius: 8px;
  margin-top: 17px;
  margin-bottom: 8px;
  padding: 0 12px;
}

.newComponentContent {
  font-size: 16px;
  padding-top: 16px;
}

.newComponentContent a {
  color: #ed0e0e;
  line-height: 32px;
  text-align: justify;
  text-transform: none;
}

:deep(.ant-divider) {
  border: 1px #e0e0e0 solid;
  margin-top: 17px;
  padding: 0;
  margin-bottom: 0;
}
</style>
