import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/images/login_image.png'


const _hoisted_1 = { id: "articleModule" }
const _hoisted_2 = { class: "articleHeader" }
const _hoisted_3 = { class: "article" }


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonArticleModuleComponent',
  props: {
  articleType: String,
  articleObject: Object,
},
  setup(__props) {

// eslint-disable-next-line no-undef


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(__props.articleType), 1),
      _createVNode(_component_router_link, { to: "/articleList" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("u", null, "查看更多", -1),
          _createTextVNode("→")
        ])),
        _: 1
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.articleObject, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item,
        class: "boxContent"
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: "/articleContent",
            class: "router-link-active"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.articleTitle), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_typography_paragraph, {
            ellipsis: _ctx.ellipsis ? { rows: 3 } : {},
            content: item.articleContent
          }, null, 8, ["ellipsis", "content"]),
          _createElementVNode("p", null, _toDisplayString(item.articleTime), 1)
        ])
      ]))
    }), 128))
  ]))
}
}

})