import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "floatMenu" }

import {
  MessageFilled,
  WechatFilled,
  PhoneFilled,
  ArrowUpOutlined,
} from "@ant-design/icons-vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonFloatMenuComponent',
  setup(__props) {

const doMessage = () => {
  window.open("https://w128.ttkefu.com/k/linkurl/?t=4G6GA0", "_blank");
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // 可选，平滑滚动
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(MessageFilled), { onClick: doMessage }),
    _createVNode(_unref(WechatFilled)),
    _createVNode(_unref(PhoneFilled)),
    _createVNode(_unref(ArrowUpOutlined), { onClick: scrollToTop })
  ]))
}
}

})