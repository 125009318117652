import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/images/Mask group@2x(1).png'


const _hoisted_1 = { id: "littleBox" }
const _hoisted_2 = { class: "introduceColumn" }


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonPanelComponent',
  props: {
  contentTitle: String,
  peopleName: String,
  time: String,
},
  setup(__props) {

// eslint-disable-next-line no-undef


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _createElementVNode("h2", null, _toDisplayString(__props.contentTitle), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: "/public/images/Rectangle%208@2x(1).png",
          alt: ""
        }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(__props.peopleName), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString(__props.time), 1)
    ])
  ]))
}
}

})