import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "live" }
const _hoisted_2 = { class: "headShow" }
const _hoisted_3 = { class: "elementOne" }
const _hoisted_4 = { class: "elementTwo" }
const _hoisted_5 = { class: "elementThree" }

import TransactionTrainingTwoView from "@/views/transactionTrainingView/live/TransactionTrainingTwoView.vue";
import CommonCharactersIntroComponent from "@/components/CommonCharactersIntroComponent.vue";
import TransactionTrainingOneView from "@/views/transactionTrainingView/live/TransactionTrainingOneView.vue";
import TransactionTrainingHeadView from "@/views/transactionTrainingView/live/TransactionTrainingHeadView.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionTrainingLiveView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TransactionTrainingHeadView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(TransactionTrainingOneView)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(TransactionTrainingTwoView)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(CommonCharactersIntroComponent)
    ])
  ]))
}
}

})