<template>
  <div id="merchantOneView">
    <h1>做市商逻辑</h1>
    <a-row justify="space-between" :gutter="24" class="merchantRow">
      <a-col :span="10">
        <a-row :gutter="[0, 24]" wrap="wrap">
          <a-col :span="24">
            <div
              class="merchantBox"
              style="border-radius: 16px 0 0 0; height: 321px"
            >
              <span>{{ titleOne }}</span>
              <router-link to="/merchantData">{{ more }}</router-link>
            </div>
          </a-col>
          <a-col :span="24">
            <div
              class="merchantBox"
              style="border-radius: 0 0 0 16px; height: 321px"
            >
              <span>做市商逻辑的价值？</span>
              <router-link to="/merchantData">{{ more }}</router-link>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="10">
        <a-row :gutter="[0, 24]" wrap="wrap">
          <a-col :span="24">
            <div class="merchantBox" style="height: 140px">
              <span class="span">{{ titleOne }}</span>
              <router-link to="/merchantData">{{ more }}</router-link>
            </div>
          </a-col>
          <a-col :span="24">
            <a-row :gutter="24">
              <a-col :span="16">
                <a-row :gutter="[0, 24]" wrap="wrap">
                  <a-col :span="24">
                    <div class="merchantBox" style="height: 240px">
                      <span>{{ titleTwo }}</span>
                      <router-link to="/merchantData">{{ more }}</router-link>
                    </div>
                  </a-col>
                  <a-col :span="24">
                    <div class="merchantBox" style="height: 240px">
                      <span>{{ titleTwo }}</span>
                      <router-link to="/merchantData">{{ more }}</router-link>
                    </div>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="8">
                <div class="merchantBox" style="height: 504px">
                  <span
                    style="
                      writing-mode: vertical-lr;
                      text-orientation: upright;
                      letter-spacing: 3px;
                    "
                    >做市商逻辑与订单流的区别</span
                  >
                  <router-link
                    to="/merchantData"
                    style="
                      writing-mode: vertical-rl;
                      text-orientation: upright;
                      letter-spacing: 3px;
                    "
                    >{{ more }}
                  </router-link>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="4">
        <div
          class="merchantBox"
          style="border-radius: 0 16px 16px 0; height: 100%"
        >
          <span
            style="
              writing-mode: vertical-lr;
              text-orientation: upright;
              letter-spacing: 3px;
            "
            >做市商逻辑与订单流分析的优劣</span
          >
          <router-link
            to="/merchantData"
            style="
              writing-mode: vertical-rl;
              text-orientation: upright;
              letter-spacing: 3px;
            "
            >{{ more }}
          </router-link>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
//
import { ref } from "vue";

const titleOne = ref("什么是做市商逻辑？");
const titleTwo = ref("为什么要学习做市商模式？");
const more = ref("了解详情");
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#merchantOneView {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#merchantOneView h1 {
  font-size: 48px;
  color: #2d211c;
  line-height: 72px;
  text-align: center;
  margin-bottom: 60px;
}

#merchantOneView .merchantRow {
  width: 100%;
  height: 668px;
}

#merchantOneView .merchantBox {
  background-color: #f1edeb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}

.merchantBox span {
  font-size: 24px;
  line-height: 36px;
  color: #2d211c;
  text-align: left;
}

.merchantBox a {
  font-size: 16px;
  line-height: 19px;
  color: #de2727;
  text-align: right;
}

#merchantOneView .merchantBox:hover {
  background-color: #271903;
  transition: background-color 0.3s ease-in-out;
}

#merchantOneView .merchantBox:hover a {
  color: white;
}

#merchantOneView .merchantBox:hover span {
  color: white;
}
</style>
