<template>
  <div id="introduceElementOne">
    <div class="frame">
      <img src="/" alt="" />
    </div>
    <div class="describe">
      <h2>Pyta创始人</h2>
      <h1>易天循</h1>
      <p>
        春节，是中华民族传统节日，是万家团圆、辞旧迎新的日子。新时代以来，每到年味渐浓之时，习近平总书记都会深入到群众当中，问冷暖送祝福、听民声解民忧。值此辞旧迎新之际，央视网推出系列特稿，与您一起循着总书记新春足迹，感悟深厚的人民情怀。
      </p>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#introduceElementOne {
  width: 1120px;
  height: auto;
  display: flex;
  justify-content: center;
}

#introduceElementOne .frame {
  width: 400px;
  height: 550px;
  border-radius: 60px 0 60px 0;
  border: 4px solid #afa8a2;
  padding: 10px;
  overflow: hidden;
}

#introduceElementOne .frame > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 60px 0 60px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#introduceElementOne .describe {
  height: 550px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.3;
  padding: 50px;
}

#introduceElementOne .describe h2 {
  font-size: 36px;
  color: #2d211c;
  line-height: 42px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  text-align: left;
}

#introduceElementOne .describe h1 {
  font-size: 64px;
  color: #2d211c;
  line-height: 75px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  text-align: left;
  margin: 4px 0 16px 0;
}

#introduceElementOne .describe p {
  font-size: 20px;
  color: #675954;
  line-height: 23px;
  text-align: justify;
}
</style>
