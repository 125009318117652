<template>
  <div id="nonFarmView">
    <h1>数据行情</h1>
    <p>
      本网页提供IB盈透证券的热门期货品种，涵盖美国、中国香港、新加坡、德国、日本等国家和地区，方便用户查询和使用。
      <br />本站会不定期更新，您可收藏本页方便查看最新数据。
      <br />请注意，部分期货品种代码一致、但乘数不同，代表不同规格的期货品种，搜索代码时，请注意选择合适的合约乘数。
    </p>
    <a-tabs v-model:activeKey="activeKey" type="card">
      <a-tab-pane key="1" tab="北美">
        <a-table
          :columns="columns"
          :data-source="northAmericaData"
          :scroll="{ y: 537 }"
        />
      </a-tab-pane>
      <a-tab-pane key="2" tab="亚太">
        <a-table
          :columns="columns"
          :data-source="asiaData"
          :scroll="{ y: 537 }"
        />
      </a-tab-pane>
      <a-tab-pane key="3" tab="欧洲">
        <a-table
          :columns="columns"
          :data-source="europeData"
          :scroll="{ y: 537 }"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import TargetCodeData from "@/data/targetCodeData.json";

const activeKey = ref("1");

const columns = [
  {
    name: "名字",
    dataIndex: "name",
    key: "名字",
  },
  {
    title: "代码",
    dataIndex: "code",
    key: "代码",
  },
  {
    title: "交易所",
    dataIndex: "exchange",
    key: "交易所",
  },
  {
    title: "类型",
    dataIndex: "type",
    key: "类型",
  },
  {
    title: "最小波动",
    dataIndex: "minFluctuate",
    key: "最小波动",
  },
  {
    title: "乘数",
    dataIndex: "multiplier",
    key: "乘数",
  },
  {
    title: "每点价值",
    dataIndex: "valueNumber",
    key: "每点价值",
  },
  {
    title: "货币",
    dataIndex: "currency",
    key: "货币",
  },
];

const northAmericaData = TargetCodeData.northAmericaData;
const asiaData = TargetCodeData.asiaData;
const europeData = TargetCodeData.europeData;
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

#nonFarmView {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 80px;
}

#nonFarmView h1 {
  font-size: 32px;
  text-align: left;
  line-height: 32px;
  margin-top: 24px;
  margin-bottom: 20px;
}

#nonFarmView p {
  width: 100%;
  font-size: 16px;
  text-align: left;
  line-height: 19px;
  color: #675954;
  margin-bottom: 32px;
}
</style>
