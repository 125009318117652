import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "transactionTrainingTwoView" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "invitation" }

import CommonPanelComponent from "@/components/CommonPanelComponent.vue";

const contentTitle = "课程标题";
const peopleName = "托尼老师";
const time = "2025-2-12 11:20";

export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionTrainingTwoView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", null, "导师直播精彩内容回顾", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
        return _createVNode(CommonPanelComponent, {
          "content-title": contentTitle,
          "people-name": peopleName,
          time: time,
          key: i
        })
      }), 64))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("span", null, "成为Pyta学员，与顶尖交易名师携手，实现长期稳定的盈利", -1)),
      _createVNode(_component_router_link, { to: "/videoList" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("u", null, "更多视频", -1),
          _createTextVNode("→")
        ])),
        _: 1
      })
    ])
  ]))
}
}

})