import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "targetCodePage" }
const _hoisted_2 = { class: "targetCodeHeadShow" }
const _hoisted_3 = { class: "targetCodeContent" }

import TargetCodeHeadShowView from "@/views/targetCode/TargetCodeHeadShowView.vue";
import TargetCodeContentView from "@/views/targetCode/TargetCodeContentView.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'TargetCodePage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(TargetCodeHeadShowView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(TargetCodeContentView)
    ])
  ]))
}
}

})