<template>
  <div id="homeContentCenter">
    <div class="homeContentHeadShow">
      <home-head-show />
    </div>
    <div class="homeElementOne">
      <home-element-one-view />
    </div>
    <div class="homeElementTwo">
      <home-element-two-view />
    </div>
    <!--<div class="homeElementThree">
          <home-element-three-view />
        </div>
    <div class="homeElementFour">
      <home-element-four-view />
    </div>
    <div class="homeElementFive">
      <home-element-five-view />
    </div>-->
    <div class="homeElementSix">
      <home-element-six-view />
    </div>
    <!--<div class="homeElementSeven">
          <home-element-seven-view />
        </div>-->
    <!-- 热门文章 -->
    <div class="homeElementEight">
      <home-element-eight-view />
    </div>
    <div class="homeSlideshow">
      <common-remark-slideshow-component
        :title="title"
        :display-style="true"
        :comment-title="commentTitle"
        :comment-content="commentContent"
      />
    </div>
    <div class="homeMore">
      <common-more-content-component />
    </div>
  </div>
</template>

<script setup lang="ts">
import HomeHeadShow from "@/views/homeView/HomeHeadShow.vue";
import HomeElementOneView from "@/views/homeView/HomeElementOneView.vue";
import HomeElementTwoView from "@/views/homeView/HomeElementTwoView.vue";
import HomeElementThreeView from "@/views/homeView/HomeElementThreeView.vue";
import HomeElementFourView from "@/views/homeView/HomeElementFourView.vue";
import HomeElementFiveView from "@/views/homeView/HomeElementFiveView.vue";
import HomeElementSixView from "@/views/homeView/HomeElementSixView.vue";
import HomeElementSevenView from "@/views/homeView/HomeElementSevenView.vue";
import HomeElementEightView from "@/views/homeView/HomeElementEightView.vue";
import CommonRemarkSlideshowComponent from "@/components/CommonRemarkSlideshowComponent.vue";
import CommonMoreContentComponent from "@/components/CommonMoreContentComponent.vue";

const title = "学员评价";
const commentTitle = "郑清予";
const commentContent =
  "投资银行利用自有资金和融入资金直接参与证券市场交易并承担风险的业务,自营交易是指投资银行利用自有资金和融入资金直接参与证券市场交易并承担风险的一项业务。";
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#homeContentCenter {
  width: 100%;
  height: auto;
}

#homeContentCenter .homeContentHeadShow {
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  background-image: url("../assets/images/home_head_show_image.png");
  background-size: cover;
  padding-top: 70px;
}

#homeContentCenter .homeElementOne {
  width: 100%;
  height: 824px;
  display: flex;
  justify-content: center;
  background-color: white;
  padding-top: 100px;
}

#homeContentCenter .homeElementTwo {
  width: 100%;
  height: 1069px;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background-color: #fafafa;
}

#homeContentCenter .homeElementThree {
  width: 100%;
  height: 515px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  background-color: white;
}

#homeContentCenter .homeElementFour {
  width: 100%;
  height: 1200px;
  display: flex;
  justify-content: center;
  background-color: #faf8f7;
}

#homeContentCenter .homeElementFive {
  width: 100%;
  height: 550px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

#homeContentCenter .homeElementSix {
  width: 100%;
  height: 850px;
  background-color: #faf8f7;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

#homeContentCenter .homeElementSeven {
  width: 100%;
  height: 824px;
  background-color: #faf8f7;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

#homeContentCenter .homeElementEight {
  width: 100%;
  height: 929px;
  background-color: white;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

#homeContentCenter .homeSlideshow {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #010818;
}

#homeContentCenter .homeMore {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #010818;
  padding-top: 60px;
}
</style>
