import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, onMounted, onBeforeUnmount } from "vue";
import * as echarts from "echarts";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'CommonEChartsComponent',
  props: {
  echartsDataObject: {
    type: Array,
    default: () => [],
  },
},
  setup(__props) {

const props = __props;
// eslint-disable-next-line vue/no-setup-props-destructure
const echartsData = props.echartsDataObject;

// 创建一个引用用于 ECharts 图表
const chart = ref(null);
let chartInstance = null;

// 初始化图表
const initChart = () => {
  chartInstance = echarts.init(chart.value);
  const options = {
    xAxis: {
      type: "category",
      data: [
        "24年10月",
        "24年11月",
        "24年12月",
        "25年1月",
        "25年2月",
        "25年3月",
        "25年4月",
      ],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: echartsData,
        type: "line",
      },
    ],
  };
  chartInstance.setOption(options);
};

// 处理窗口调整大小
const handleResize = () => {
  if (chartInstance) {
    chartInstance.resize();
  }
};

onMounted(() => {
  initChart();
  window.addEventListener("resize", handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
  if (chartInstance) {
    chartInstance.dispose(); // 清理图表实例
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "echarts",
    ref_key: "chart",
    ref: chart
  }, null, 512))
}
}

})