<template>
  <div id="articleDetails">
    <div class="detailsHead">
      <h1>{{ name }}的实战成果展示</h1>
      <div>
        <div>
          <p>来源：{{ name }}</p>
          <p>时间：2025-1-18 08:00</p>
        </div>
        <div>
          <p>分享：{{ content }}</p>
          <QqCircleFilled
            style="
              color: #fff2e4;
              background-color: #faad08;
              border-radius: 50%;
            "
          />
          <WeiboCircleFilled
            style="
              color: #ffe8e4;
              background-color: #e54f00;
              border-radius: 50%;
            "
          />
          <WechatFilled style="color: #2aae67" />
        </div>
      </div>
    </div>
    <div class="detailsSummarize">
      <a-typography-paragraph :ellipsis="ellipsis ? { height: '12px' } : {}"
        ><span>概述：</span>
      </a-typography-paragraph>
    </div>
    <div class="detailsText">
      <img :src="imageUrl" alt="" />
    </div>
    <a-divider style="height: 1px; background-color: #ebebeb" />
    <div class="detailsSupplement">
      <p>
        <WarningFilled style="color: #ed0e0e" />
        风险提示及免责条款：市场有风险，投资需谨慎。本文不构成个人投资建议，也未考虑到个别用户特殊的投资目标、财务状况或需要。用户应考虑本文中的任何意见、观点或结论是否符合其特定状况。据此投资，责任自负。
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  QqCircleFilled,
  WeiboCircleFilled,
  WechatFilled,
  WarningFilled,
} from "@ant-design/icons-vue";
import { ref } from "vue";

// eslint-disable-next-line no-undef
defineProps({
  name: String,
  imageUrl: String,
});

const content = ref("");
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#articleDetails {
  width: 100%;
  height: auto;
  padding-top: 32px;
}

#articleDetails .detailsHead {
  width: 100%;
  height: 90px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#articleDetails .detailsHead h1 {
  height: 54px;
  font-size: 36px;
  color: #2d211c;
  line-height: 54px;
  text-align: left;
}

#articleDetails .detailsHead > div {
  height: 24px;
  display: flex;
  justify-content: space-between;
}

#articleDetails .detailsHead > div div {
  height: 100%;
  display: flex;
  align-items: center;
}

#articleDetails .detailsHead > div p {
  font-size: 14px;
  color: #807d7c;
  line-height: 21px;
  text-align: justify;
  margin-right: 32px;
}

#articleDetails .detailsHead > div div:last-child p {
  margin-right: unset;
}

#articleDetails .detailsHead :deep(.anticon) {
  font-size: 24px;
  margin-left: 10px;
}

#articleDetails .detailsSummarize {
  width: 100%;
  height: 127px;
  background-color: #f0f4f9;
  padding: 16px 20px;
}

#articleDetails .detailsSummarize :deep(.ant-typography) {
  font-size: 16px;
  color: #2d211c;
  line-height: 32px;
  text-align: justify;
}

#articleDetails .detailsSummarize span {
  color: #807d7c;
}

#articleDetails .detailsText {
  width: 100%;
  height: auto;
  text-align: justify;
  margin-top: 40px;
  margin-bottom: 32px;
  overflow: hidden;
}

#articleDetails .detailsText img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

#articleDetails .detailsSupplement {
  width: 100%;
  height: 127px;
  font-size: 16px;
  color: #675954;
  line-height: 32px;
  text-align: justify;
  background-color: #f9f0f0;
  margin-top: 32px;
  padding: 16px 20px;
}
</style>
