<template>
  <div id="videoSchoolPage">
    <div class="videoSchoolHeadShow">
      <video-school-head-view />
    </div>
    <div class="elementOne">
      <video-school-element-one-view />
    </div>
    <div class="elementTwo">
      <video-school-element-two-view />
    </div>
    <div class="elementThree">
      <common-process-component
        title="如何购买及查看已购课程视频"
        :step-object="stepObject"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import VideoSchoolHeadView from "@/views/videoSchoolView/VideoSchoolHeadView.vue";
import VideoSchoolElementOneView from "@/views/videoSchoolView/VideoSchoolElementOneView.vue";
import VideoSchoolElementTwoView from "@/views/videoSchoolView/VideoSchoolElementTwoView.vue";
import CommonProcessComponent from "@/components/CommonProcessComponent.vue";
import { ref } from "vue";

const stepObject = ref([
  {
    number: 1,
    content: "点击视频课程后按购买按钮跳转到支付页面完成支付。",
    activeDisplay: "none",
  },
  {
    number: 2,
    content: "进入个人中心，我的课程栏目即可查看已购课程视频。",
    activeDisplay: "none",
  },
  {
    number: 3,
    content:
      "购买课程后联系在线客服或者助教，拉入小班级群，名师在线辅导，实现自我突破。",
    activeDisplay: "none",
  },
]);
</script>

<style scoped>
#videoSchoolPage {
  width: 100%;
  height: auto;
}

#videoSchoolPage .videoSchoolHeadShow {
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  background-image: url("../assets/images/video_head_show_image.png");
  background-size: cover;
  padding-top: 70px;
}

#videoSchoolPage .elementOne {
  width: 100%;
  height: 930px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

#videoSchoolPage .elementTwo {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #f7faf8;
}

#videoSchoolPage .elementThree {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-color: white;
  padding-top: 50px;
}
</style>
