<template>
  <div id="floatMenu">
    <MessageFilled @click="doMessage" />
    <WechatFilled />
    <PhoneFilled />
    <ArrowUpOutlined @click="scrollToTop" />
  </div>
</template>

<script setup lang="ts">
import {
  MessageFilled,
  WechatFilled,
  PhoneFilled,
  ArrowUpOutlined,
} from "@ant-design/icons-vue";

const doMessage = () => {
  window.open("https://w128.ttkefu.com/k/linkurl/?t=4G6GA0", "_blank");
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // 可选，平滑滚动
  });
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#floatMenu {
  width: 48px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#floatMenu :deep(.anticon) {
  width: 48px;
  height: 48px;
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

#floatMenu :deep(.anticon):hover {
  background-color: #e3e3e3;
  transition: background-color 0.3s;
}

#floatMenu :deep(.anticon-message) {
  color: #0077ff;
}

#floatMenu :deep(.anticon-wechat) {
  color: #2aae67;
}

#floatMenu :deep(.anticon-phone) {
  color: #ff5300;
}

#floatMenu :deep(.anticon-vertical-align-top) {
  color: #2d211c;
}
</style>
