<template>
  <div id="merchantData">
    <div></div>
    <div>
      <div>
        <common-breadcrumb-component :routes="routes" />
        <div
          :id="item.title"
          v-for="(item, index) in merchantDataObject"
          :key="index"
        >
          <h1>{{ item.title }}</h1>
          <p>时间：{{ dataTime }}</p>
          <p>{{ merchantDataContent }}</p>
          <a-divider />
        </div>
      </div>
      <div>
        <a-anchor :target-offset="targetOffset" @click="onChange">
          <p>资料</p>
          <a-anchor-link
            v-for="(item, index) in merchantDataObject"
            :key="index"
            :title="item.title"
            :href="'#' + item.title"
          />
        </a-anchor>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonBreadcrumbComponent from "@/components/CommonBreadcrumbComponent.vue";
import { onMounted, ref } from "vue";

const merchantDataObject = ref([
  {
    title: "什么是做市商逻辑",
  },
  {
    title: "订单流是什么？",
  },
  {
    title: "做市商逻辑的价值？",
  },
  {
    title: "为什么要学习做市商模式？",
  },
  {
    title: "做市商逻辑适合什么产品？",
  },
  {
    title: "做市商逻辑与订单流的区别？",
  },
  {
    title: "做市商逻辑与订单流分析的优势",
  },
]);

const merchantDataContent = ref(
  `做市商(Market Maker)是指在市场中持续提供买卖报价、维护市场流动性的一类市场参与者。做市商的主要职责是为特定金融产品(如股票、期货或外汇)提供双向报价，即同时发布买入价和卖出价，以确保市场中交易的顺畅进行。
  主要逻辑：做市商通过持续买卖来赚取买卖价差(Bid-AskSpread)，而不是靠市场的趋势获利。做市商的目标是通过在市场中持续进行交易，确保自己能从频繁的成交中获取稳定的利润。
  风险管理：做市商通过库存管理和对冲策略来规避风险。当做市商积累了较大的头寸时，他们会通过对冲操作或调整报价来减轻风险。
  市场流动性：做市商的作用主要在于为市场提供充足的流动性，使得买卖双方能够以更小的滑点完成交易。通过不断调整报价，做市商确保在市场缺乏流动性时，价格波动不会过于剧烈。
  行为特点：做市商通常不会跟随市场的价格趋势，而是通过观察市场中的供需情况调整自己的买卖报价，保持一定的中立性，以确保其库存的平衡。`
);

const dataTime = ref("2025-2-16 00:00:00");

interface Route {
  path: string;
  breadcrumbName: string;
}

const routes = ref<Route[]>([
  {
    path: "/home",
    breadcrumbName: "首页",
  },
  {
    //学员焦点
    path: "#homeElementEightView",
    breadcrumbName: "学员焦点",
  },
  {
    // 文章标题
    path: "",
    breadcrumbName: "articleTitle",
  },
]);

/*const targetOffset = ref<number | undefined>(undefined);
onMounted(() => {
  targetOffset.value = window.innerHeight / 4;
});*/
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#merchantData {
  width: 100%;
  height: auto;
}

#merchantData > div:first-child {
  width: 100%;
  height: 70px;
  background-color: #010818;
}

#merchantData > div:last-child {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 67px;
}

#merchantData > div:last-child > div:first-child {
  width: 790px;
  height: auto;
  margin-right: 15px;
}

#merchantData > div:last-child > div:first-child > div:not(:first-child) {
  width: 100%;
  height: auto;
  padding-top: 32px;
}

#merchantData > div:last-child > div:first-child > div:not(:first-child) h1 {
  width: 100%;
  height: 54px;
  font-size: 36px;
  color: #2d211c;
  line-height: 54px;
  text-align: left;
  margin-bottom: 12px;
}

#merchantData
  > div:last-child
  > div:first-child
  > div:not(:first-child)
  p:nth-child(2) {
  width: 100%;
  height: 21px;
  font-size: 14px;
  color: #807d7c;
  line-height: 21px;
  text-align: justify;
  margin-bottom: 30px;
}

#merchantData
  > div:last-child
  > div:first-child
  > div:not(:first-child)
  p:nth-child(3) {
  width: 100%;
  height: auto;
  font-size: 16px;
  color: #2d211c;
  line-height: 32px;
  text-align: justify;
  margin-bottom: 33px;
  text-indent: 2em;
}

:deep(.ant-divider) {
  width: 789px;
  height: 1px;
  background: #ebebeb;
}

#merchantData > div:last-child > div:last-child {
  width: 380px;
  margin-left: 15px;
}

:deep(.ant-anchor) > p {
  font-size: 16px;
  color: #675954;
  line-height: 22px;
  text-align: left;
  margin-left: 8px;
}

:deep(.ant-anchor-link) {
  margin: 10px 0;
}

:deep(.ant-anchor-wrapper .ant-anchor .ant-anchor-link-title) {
  font-size: 16px;
  color: #675954;
  line-height: 22px;
  text-align: left;
}

:deep(
    .ant-anchor-wrapper
      .ant-anchor
      .ant-anchor-link-active
      > .ant-anchor-link-title
  ) {
  color: #2d211c;
}
</style>
