<template>
  <div id="transactionTrainingPage">
    <router-view />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#transactionTrainingPage {
  width: 100%;
  height: auto;
}
</style>
