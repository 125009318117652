import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "studyShow" }
const _hoisted_2 = { class: "studyShowHeadShow" }
const _hoisted_3 = { class: "startScholar" }
const _hoisted_4 = { class: "studyShowContent" }

import { ref } from "vue";
import HotNewsHeadShowView from "@/views/hotNewsView/HotNewsHeadShowView.vue";
import StudyShowPanelComponent from "@/components/StudyShowPanelComponent.vue";
import StudyShowView from "@/views/studyShow/StudyShowView.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyShowPage',
  setup(__props) {

const panelObject = ref([
  {
    contentTitle: "明星学员 二期线下班",
    peopleName: "陈同学",
    time: "2024-11-21",
    imgUrl: require("@/assets/studyShow/11-21陈同学1.jpg"),
  },
  {
    contentTitle: "明星学员 二期线下班",
    peopleName: "王同学",
    time: "2024-11-24",
    imgUrl: require("@/assets/studyShow/2-21liu.jpg"),
  },
  {
    contentTitle: "明星学员 四期线下班",
    peopleName: "刘同学",
    time: "2025-02-18",
    imgUrl: require("@/assets/studyShow/2-21song.jpg"),
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(HotNewsHeadShowView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", null, "明星学员", -1)),
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(panelObject.value, (item, index) => {
          return (_openBlock(), _createBlock(StudyShowPanelComponent, {
            key: index,
            "content-title": item.contentTitle,
            "people-name": item.peopleName,
            time: item.time,
            "img-url": item.imgUrl
          }, null, 8, ["content-title", "people-name", "time", "img-url"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(StudyShowView)
    ])
  ]))
}
}

})