import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "homeContentCenter" }
const _hoisted_2 = { class: "homeContentHeadShow" }
const _hoisted_3 = { class: "homeElementOne" }
const _hoisted_4 = { class: "homeElementTwo" }
const _hoisted_5 = { class: "homeElementSix" }
const _hoisted_6 = { class: "homeElementEight" }
const _hoisted_7 = { class: "homeSlideshow" }
const _hoisted_8 = { class: "homeMore" }

import HomeHeadShow from "@/views/homeView/HomeHeadShow.vue";
import HomeElementOneView from "@/views/homeView/HomeElementOneView.vue";
import HomeElementTwoView from "@/views/homeView/HomeElementTwoView.vue";
import HomeElementThreeView from "@/views/homeView/HomeElementThreeView.vue";
import HomeElementFourView from "@/views/homeView/HomeElementFourView.vue";
import HomeElementFiveView from "@/views/homeView/HomeElementFiveView.vue";
import HomeElementSixView from "@/views/homeView/HomeElementSixView.vue";
import HomeElementSevenView from "@/views/homeView/HomeElementSevenView.vue";
import HomeElementEightView from "@/views/homeView/HomeElementEightView.vue";
import CommonRemarkSlideshowComponent from "@/components/CommonRemarkSlideshowComponent.vue";
import CommonMoreContentComponent from "@/components/CommonMoreContentComponent.vue";

const title = "学员评价";
const commentTitle = "郑清予";
const commentContent =
  "投资银行利用自有资金和融入资金直接参与证券市场交易并承担风险的业务,自营交易是指投资银行利用自有资金和融入资金直接参与证券市场交易并承担风险的一项业务。";

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeContentPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(HomeHeadShow)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(HomeElementOneView)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(HomeElementTwoView)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(HomeElementSixView)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(HomeElementEightView)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(CommonRemarkSlideshowComponent, {
        title: title,
        "display-style": true,
        "comment-title": commentTitle,
        "comment-content": commentContent
      })
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(CommonMoreContentComponent)
    ])
  ]))
}
}

})