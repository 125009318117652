import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "newComponent" }
const _hoisted_2 = { key: 0 }

import { ref } from "vue";
import dayjs, { Dayjs } from "dayjs";

const dateFormat = "YYYY-MM-DD";

interface Route {
  path: string;
  breadcrumbName: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonNewsComponent',
  props: {
  contentObject: Object,
},
  setup(__props) {

const time = ref<Dayjs>(dayjs(new Date().getDay().toString(), dateFormat));

const routes = ref<Route[]>([
  {
    path: "/home",
    breadcrumbName: "首页",
  },
  {
    path: "/hotNews",
    breadcrumbName: "热点新闻",
  },
  {
    path: "/news",
    breadcrumbName: "红点新闻",
  },
]);

// eslint-disable-next-line no-undef


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_breadcrumb, { routes: routes.value }, {
      itemRender: _withCtx(({ route, paths }) => [
        (routes.value.indexOf(route) === routes.value.length - 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(route.breadcrumbName), 1))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: `/${paths.join('/')}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(route.breadcrumbName), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
      ]),
      _: 1
    }, 8, ["routes"]),
    _createVNode(_component_a_date_picker, {
      value: time.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((time).value = $event)),
      format: dateFormat
    }, null, 8, ["value"]),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.contentObject, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "newComponentContent",
            key: item.id
          }, [
            _createElementVNode("p", null, _toDisplayString(item.time), 1),
            _createVNode(_component_router_link, { to: "/articleContent" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.content), 1)
              ]),
              _: 2
            }, 1024),
            (i != 10)
              ? (_openBlock(), _createBlock(_component_a_divider, { key: 0 }))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ], 64))
    }), 64))
  ]))
}
}

})