<template>
  <div id="hotNewsContentView">
    <div class="hotNewsContentViewLeft">
      <common-news-component :content-object="contentObject" />
    </div>
    <div class="hotNewsContentViewRight">
      <common-video-recommendation-component />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonNewsComponent from "@/components/CommonNewsComponent.vue";
import { ref } from "vue";
import CommonVideoRecommendationComponent from "@/components/CommonVideoRecommendationComponent.vue";

interface ContentObjects {
  id: number;
  time: string;
  content: string;
}

const contentObject = ref<ContentObjects[]>([
  {
    id: 1,
    time: "21:21:21",
    content:
      "股市资金流向图：今日沪深两市主力资金净流出207.47亿元，占比2.15%；大单资金净流出120.10亿元，占比1.24%；小单资金净流入243.72亿元，占比2.52%。",
  },
]);
</script>

<style scoped>
#hotNewsContentView {
  width: 100%;
  height: auto;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.hotNewsContentViewLeft {
  margin-right: 15px;
}

.hotNewsContentViewRight {
  margin-left: 15px;
}
</style>
