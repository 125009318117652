<template>
  <div id="transactionTrainingHeadView">
    <h2>Pyta直播学院</h2>
    <h1>现场实操&nbsp;感受零距离</h1>
    <p>
      与专家零距离互动，快速消除你的交易盲区<br />汇集多位来自全亚洲的顶尖讲师为投资人带来全面的交易自营经验，帮助各位在交易道路上快速成长
    </p>
    <div class="contentInvite">
      <span
        >成为Pyta全球合作导师，与顶尖交易名师携手，共创全球一流自营交易学院</span
      >
      <a href="https://baidu.com" target="_blank" style="color: #de2727"
        ><u>立即加入Pyta</u>→</a
      >
    </div>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

#transactionTrainingHeadView {
  width: 1200px;
  height: auto;
  margin-top: 168px;
  color: white;
}

#transactionTrainingHeadView h2 {
  font-size: 40px;
  line-height: 60px;
  text-align: left;
}

#transactionTrainingHeadView h1 {
  font-size: 64px;
  line-height: 75px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  text-align: left;
  margin-top: 16px;
  margin-bottom: 24px;
}

#transactionTrainingHeadView p {
  width: 600px;
  font-size: 20px;
  line-height: 23px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  text-align: left;
}
.contentInvite {
  width: 100%;
  height: 22px;
  display: flex;
  justify-content: space-between;
  margin-top: 165px;
  font-size: 16px;
}

.contentInvite span {
  font-size: 16px;
}
</style>
