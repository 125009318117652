import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "studyShowDetails" }
const _hoisted_2 = { class: "articleContentLeft" }
const _hoisted_3 = { class: "articleContentRight" }

import { useRoute } from "vue-router";
import CommonVideoRecommendationComponent from "@/components/CommonVideoRecommendationComponent.vue";
import StudyShowOneView from "@/views/studyShow/more/StudyShowOneView.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyShowDetailsView',
  setup(__props) {

const route = useRoute();
const transmitId = route.params.id;

const imgUrl = () => {
  switch (transmitId) {
    case `1`:
      return {
        name: "陈同学",
        imageUrl: require("@/assets/studyShow/11-21陈同学-1in.jpg"),
      };
    case `2`:
      return {
        name: "王同学",
        imageUrl: require("@/assets/studyShow/1-4王同学-1in.jpg"),
      };
    case `3`:
      return {
        name: "刘同学",
        imageUrl: require("@/assets/studyShow/2-18刘同学-1in.jpg"),
      };
    case `4`:
      return {
        name: "王同学",
        imageUrl: require("@/assets/studyShow/2-18王同学-in.jpg"),
      };
    case `5`:
      return {
        name: "周同学",
        imageUrl: require("@/assets/studyShow/2-10周同学-in.jpg"),
      };
    case `6`:
      return {
        name: "远同学",
        imageUrl: require("@/assets/studyShow/12-26远同学-in.jpg"),
      };
    case `7`:
      return {
        name: "陈同学",
        imageUrl: require("@/assets/studyShow/2-10陈同学-in.jpg"),
      };
    case `8`:
      return {
        name: "宋同学",
        imageUrl: require("@/assets/studyShow/2-14宋同学-in.jpg"),
      };
    case `9`:
      return {
        name: "周同学",
        imageUrl: require("@/assets/studyShow/1-9周同学-1in.jpg"),
      };
    case `10`:
      return {
        name: "胡同学",
        imageUrl: require("@/assets/studyShow/11-21胡同学.jpg"),
      };
    case `11`:
      return {
        name: "廖同学",
        imageUrl: require("@/assets/studyShow/11-21廖同学.jpg"),
      };
    case `12`:
      return {
        name: "陆同学",
        imageUrl: require("@/assets/studyShow/11-21陆同学.jpg"),
      };
    case `13`:
      return {
        name: "谭同学",
        imageUrl: require("@/assets/studyShow/11-21谭同学.jpg"),
      };
    case `14`:
      return {
        name: "姚同学",
        imageUrl: require("@/assets/studyShow/1-17姚同学-in.jpg"),
      };
    case `15`:
      return {
        name: "耿同学",
        imageUrl: require("@/assets/studyShow/1-9耿同学-in.jpg"),
      };
    case `16`:
      return {
        name: "辉同学",
        imageUrl: require("@/assets/studyShow/1-21辉同学-in.jpg"),
      };
    case `17`:
      return {
        name: "郭同学",
        imageUrl: require("@/assets/studyShow/12-27郭同学-in.jpg"),
      };
    case `18`:
      return {
        name: "韩同学",
        imageUrl: require("@/assets/studyShow/12-26韩同学-in.jpg"),
      };
    case `19`:
      return {
        name: "何同学",
        imageUrl: require("@/assets/studyShow/1-16何同学-in.jpg"),
      };
    case `20`:
      return {
        name: "梁同学",
        imageUrl: require("@/assets/studyShow/1-9梁同学-in.jpg"),
      };
    case `21`:
      return {
        name: "陆同学",
        imageUrl: require("@/assets/studyShow/12-16陆同学-in.jpg"),
      };
    case `22`:
      return {
        name: "王同学",
        imageUrl: require("@/assets/studyShow/12-16王同学-in.jpg"),
      };
    case `23`:
      return {
        name: "肖同学",
        imageUrl: require("@/assets/studyShow/12-27肖同学-in.jpg"),
      };
    case `24`:
      return {
        name: "赵同学",
        imageUrl: require("@/assets/studyShow/1-16赵同学-in.jpg"),
      };
    case `25`:
      return {
        name: "范同学",
        imageUrl: require("@/assets/studyShow/1-16范同学-in.jpg"),
      };
    case `26`:
      return {
        name: "凌同学",
        imageUrl: require("@/assets/studyShow/2-14凌同学-in.jpg"),
      };
    case `27`:
      return {
        name: "知行同学",
        imageUrl: require("@/assets/studyShow/2-20知行同学-in.jpg"),
      };
    default:
      return {
        name: "陈同学",
        imageUrl: require("@/assets/studyShow/11-21陈同学-2in.jpg"),
      };
  }
};
const name = imgUrl().name;
const imageUrl = imgUrl().imageUrl;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1)),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(StudyShowOneView, {
          name: _unref(name),
          "image-url": _unref(imageUrl)
        }, null, 8, ["name", "image-url"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(CommonVideoRecommendationComponent)
      ])
    ])
  ]))
}
}

})