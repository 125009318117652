import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "introducePage" }
const _hoisted_2 = { class: "introduceHeadShow" }
const _hoisted_3 = { class: "elementOne" }
const _hoisted_4 = { class: "elementTwo" }
const _hoisted_5 = { class: "elementThree" }
const _hoisted_6 = { class: "elementFour" }
const _hoisted_7 = { class: "elementFive" }
const _hoisted_8 = { class: "elementSix" }
const _hoisted_9 = { class: "homeSlideshow" }

import IntroduceHeadView from "@/views/introduceView/IntroduceHeadView.vue";
import IntroduceElementOneView from "@/views/introduceView/IntroduceElementOneView.vue";
import IntroduceElementTwoView from "@/views/introduceView/IntroduceElementTwoView.vue";
import IntroduceElementThreeView from "@/views/introduceView/IntroduceElementThreeView.vue";
import IntroduceElementFourView from "@/views/introduceView/IntroduceElementFourView.vue";
import IntroduceElementFiveView from "@/views/introduceView/introduceElementFiveView.vue";
import CommonProcessComponent from "@/components/CommonProcessComponent.vue";
import CommonRemarkSlideshowComponent from "@/components/CommonRemarkSlideshowComponent.vue";
import { ref } from "vue";

const title = "FAQ 问答";
const commentTitle = "Pyta课程包含那些内容";
const commentContent =
  "投资银行利用自有资金和融入资金直接参与证券市场交易并承担风险的业务,自营交易是指投资银行利用自有资金和融入资金直接参与证券市场交易并承担风险的一项业务。";


export default /*@__PURE__*/_defineComponent({
  __name: 'IntroducePage',
  setup(__props) {

const stepObject = ref([
  {
    number: 1,
    content: "注册成为会员，领取会员学习资料，分配助教。",
    activeDisplay: "inline",
  },
  {
    number: 2,
    content: "助教根据学员情况交易经验，合理选择对应课程，并分配老师。",
    activeDisplay: "none",
  },
  {
    number: 3,
    content: "根据课程安排，每日与顶尖老师一起学习最新的交易策略和交易技巧。",
    activeDisplay: "none",
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(IntroduceHeadView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(IntroduceElementOneView)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(IntroduceElementTwoView)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(IntroduceElementThreeView)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(IntroduceElementFourView)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(IntroduceElementFiveView)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(CommonProcessComponent, {
        title: "如何成为学员和参与培训",
        "step-object": stepObject.value
      }, null, 8, ["step-object"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(CommonRemarkSlideshowComponent, {
        title: title,
        "display-style": false,
        "comment-title": commentTitle,
        "comment-content": commentContent
      })
    ])
  ]))
}
}

})