<template>
  <div id="hotHeadLineView">
    <div class="hotHeadLineViewLeft">
      <common-hot-headline-component :head-line-object="headLineObject" />
    </div>
    <div class="hotHeadLineViewRight">
      <CommonVideoRecommendationComponent />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonVideoRecommendationComponent from "@/components/CommonVideoRecommendationComponent.vue";
import CommonHotHeadlineComponent from "@/components/CommonHotHeadlineComponent.vue";
import { ref } from "vue";

interface HeadLineObjects {
  id: number;
  headLineTitle: string;
  headLineContent: string;
  imageUrl: string;
  headLineTime: string;
  headLineSource: string;
}

const headLineObject = ref<HeadLineObjects[]>([
  {
    id: 0,
    headLineTitle:
      "经济学家高善文：25-59岁人口失业率创新低，简单讨论失业率非常不完整",
    headLineContent:
      "在核污染水处置问题上，日方应正视国际社会的合理关切，本着对全球海洋环境和公众健康负责任的态度，同包括周边邻国在内的利益攸关方开展充分、有意义的协商。日方继续强推核污染水排海，只会激起更强烈的反对。",
    imageUrl: "../../assets/images/people.jpg",
    headLineTime: "2025-1-18 18:00:00",
    headLineSource: "今日头条",
  },
]);
</script>

<style scoped>
#hotHeadLineView {
  width: 100%;
  height: auto;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.hotHeadLineViewLeft {
  margin-right: 15px;
}

.hotHeadLineViewRight {
  margin-left: 15px;
}
</style>
