<template>
  <div id="allVideoAndFilter">
    <div>
      <h1>视频合集</h1>
      <p>高级筛选</p>
    </div>
    <div style="display: none"></div>
    <div>
      <div v-for="i in 24" :key="i">
        <video controls>
          <source src="" type="video/mp4" />
        </video>
        <div>
          <router-link to="/videoDetailsView">天循老师直播录像视频</router-link>
          <div>
            <img src="../../assets/images/Rectangle%208@2x(2).png" alt="" />
            <p>托尼老师</p>
            <p>2025-1-18 08:00</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#allVideoAndFilter {
  width: 100%;
  height: auto;
}

#allVideoAndFilter > div:nth-child(1) {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  position: relative;
}

#allVideoAndFilter > div:nth-child(1) h1 {
  font-size: 32px;
  color: #2d211c;
  line-height: 32px;
  text-align: left;
}

#allVideoAndFilter > div:nth-child(1) p {
  font-size: 16px;
  color: #de2727;
  line-height: 19px;
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 150px;
}

#allVideoAndFilter > div:nth-child(2) {
}

#allVideoAndFilter > div:nth-child(3) {
  width: 100%;
  height: 1766px;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

#allVideoAndFilter > div:nth-child(3) > div {
  width: 282px;
  height: 261px;
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  border: 1px #ebebeb solid;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#allVideoAndFilter > div:nth-child(3) > div > video {
  width: 100%;
  height: 160px;
  border-radius: 16px 16px 0 0;
  cursor: pointer;
}

#allVideoAndFilter > div:nth-child(3) > div > div {
  width: 100%;
  height: auto;
  padding: 24px 24px 0 24px;
}

#allVideoAndFilter > div:nth-child(3) > div > div a {
  height: 28px;
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  color: #2d211c;
}

#allVideoAndFilter > div:nth-child(3) > div > div div {
  height: 24px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

#allVideoAndFilter > div:nth-child(3) > div > div div img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
}

#allVideoAndFilter > div:nth-child(3) > div > div div p {
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #675954;
}
</style>
