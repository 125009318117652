import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "videoList" }
const _hoisted_2 = { class: "videoBody" }

import CommonBreadcrumbComponent from "@/components/CommonBreadcrumbComponent.vue";
import AllVideoAndFilterView from "@/views/video/AllVideoAndFilterView.vue";
import { ref } from "vue";

interface Route {
  path: string;
  breadcrumbName: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoListView',
  setup(__props) {

const routes = ref<Route[]>([
  {
    path: "/videoSchool",
    breadcrumbName: "视频学院",
  },
  {
    path: "",
    breadcrumbName: "视频集",
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "videoHead" }, [
      _createElementVNode("h1", null, "Pyta视频")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(CommonBreadcrumbComponent, { routes: routes.value }, null, 8, ["routes"]),
      _cache[0] || (_cache[0] = _createStaticVNode("<div data-v-d385e2b4><video controls=\"controls\" data-v-d385e2b4><source src=\"\" type=\"video/mp4\" data-v-d385e2b4></video><div data-v-d385e2b4><video controls=\"controls\" data-v-d385e2b4><source src=\"\" type=\"video/mp4\" data-v-d385e2b4></video><video controls=\"controls\" data-v-d385e2b4><source src=\"\" type=\"video/mp4\" data-v-d385e2b4></video></div></div>", 1)),
      _createVNode(AllVideoAndFilterView)
    ])
  ]))
}
}

})