<template>
  <div id="vipHeadShowView">
    <h1>探索</h1>
    <h2>Pyta会员权益</h2>
    <p>
      自团队成立以来，Pyta以获得数千从业者的青睐，证明了我们对成功的承诺与实力
    </p>
    <div>
      <a-button>注册会员</a-button>
      <a-button>开通VIP</a-button>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#vipHeadShowView {
  width: 1200px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 122px;
}

#vipHeadShowView h1 {
  font-size: 88px;
  text-align: center;
  line-height: 132px;
}

#vipHeadShowView h2 {
  font-size: 56px;
  text-align: center;
  line-height: 84px;
}

#vipHeadShowView p {
  font-size: 20px;
  text-align: center;
  line-height: 23px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  margin-top: 20px;
  margin-bottom: 60px;
}

#vipHeadShowView div :deep(.ant-btn) {
  width: 200px;
  height: 42px;
  font-size: 16px;
  line-height: 19px;
  border: none;
}

#vipHeadShowView div :deep(.ant-btn):first-child {
  color: white;
  background-color: #de2727;
  margin-right: 24px;
}

#vipHeadShowView div :deep(.ant-btn):last-child {
  color: #de2727;
  background-color: white;
}
</style>
