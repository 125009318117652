<!-- 导航栏 -->
<template>
  <div id="commonGlobalHeader" ref="navigationBarRef">
    <a-row class="commonGlobalHeader-commonRow">
      <a-col flex="60px">
        <div class="commonGlobalHeader-logo">
          <img src="../../public/logo.png" alt="logo" />
        </div>
      </a-col>
      <a-col flex="auto">
        <a-menu
          v-model:selectedKeys="current"
          mode="horizontal"
          :items="items"
          @click="doMenuClick"
        />
      </a-col>
      <a-col flex="200px">
        <div class="commonGlobalHeader-buttons" style="display: none">
          <a-button
            ghost
            style="color: white; border: none"
            size="large"
            @click="error"
            >登录
          </a-button>
          <a-button
            type="primary"
            style="color: white; background-color: #e43830; border: none"
            size="large"
            @click="error"
            >免费注册
          </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { MenuProps } from "ant-design-vue";
import { useRouter } from "vue-router";
import { message, ItemType } from "ant-design-vue";

const error = () => {
  message.error("抱歉！该功能暂未开放");
};

/*获取路由跳转器*/
const router = useRouter();
/*路由跳转实现*/
const doMenuClick = ({ key }: { key: string }) => {
  router.push({ path: key });
};

const current = ref<string[]>(["home"]);
/*监听路由变化，更新选中菜单的状态*/
router.afterEach((to, from, next) => {
  current.value = [to.path];
});

const navigationBarRef = ref(null);

onMounted(() => {
  // 等待组件挂载后再访问 ref
  setTimeout(() => {
    if (navigationBarRef.value) {
      navigationBarRef.value.scrollIntoView({ behavior: "smooth" });
    }
  }, 0);
});

router.afterEach((to, from) => {
  // 等待路由完全过渡后再滚动（可选，可获得更流畅的体验）
  setTimeout(() => {
    if (navigationBarRef.value) {
      navigationBarRef.value.scrollIntoView({ behavior: "smooth" });
    }
  }, 100); // 根据需要调整延迟
});

function getItem(
  key: string,
  label: any,
  title: string,
  children?: ItemType[],
  type?: "group"
): ItemType {
  return {
    key,
    title,
    children,
    label,
    type,
  } as ItemType;
}

const items = ref<ItemType[]>([
  getItem("/", "首页", "首页"),
  getItem("/tutorDynamics", "导师动态", "导师动态"),
  getItem("/studyShow", "学员风采", "学员风采"),
  /*getItem("/pyta", "Pyta", "Pyta"),
  getItem("/videoSchool", "视频学院", "视频学院"),
  getItem(
    "/hotNews/hot",
    "热点新闻",
    "热点新闻"
    /!*    [
        getItem("/hotNews/news", "红点新闻", "红点新闻"),
        getItem("/hotNews/hot", "热点头条", "热点头条"),]*!/
  ),
  getItem("/transactionTraining", "交易培训", "交易培训", [
    getItem("/transactionTraining/live", "直播", "直播"),
    getItem("/transactionTraining/software", "交易软件", "交易软件"),
    getItem("/transactionTraining/merchant", "市商逻辑", "市商逻辑"),
  ]),
  getItem("/targetCode", "指标代码", "指标代码"),
  getItem("/aboutMe", "关于我们", "关于我们"),
  getItem("/vip", "VIP会员", "VIP会员"),*/
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#commonGlobalHeader {
  width: 1200px;
  height: 70px;
  color: white;
}

#commonGlobalHeader .commonGlobalHeader-commonRow {
  width: 100%;
  height: 100%;
}

#commonGlobalHeader .commonGlobalHeader-logo {
  width: 60px;
  height: 100%;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  overflow: hidden;
}

#commonGlobalHeader .commonGlobalHeader-logo img {
  width: 100%;
  height: 20px;
}

#commonGlobalHeader :deep(.ant-menu) {
  height: 100%;
  font-size: 16px;
  background-color: transparent;
  color: white;
}

#commonGlobalHeader :deep(.ant-menu-overflow-item) {
  height: 57%;
  padding-inline: unset !important;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px 8px;
}

#commonGlobalHeader :deep(.ant-menu-title-content) {
  margin: 0 16px;
}

#commonGlobalHeader :deep(.ant-menu-overflow-item:hover),
:deep(.ant-menu-title-content:hover) {
  /*  background-color: rgba(66, 58, 51, 0.5) !important;
    border-radius: 8px;*/
  color: white !important;
}

:deep(.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected) {
  color: unset;
  background-color: rgba(66, 58, 51, 0.5) !important;
  border-radius: 8px;
}

#commonGlobalHeader :deep(.ant-menu-horizontal > .ant-menu-item::after) {
  position: unset !important;
}

#commonGlobalHeader :deep(.ant-menu-horizontal > .ant-menu-submenu::after) {
  position: unset !important;
}

#commonGlobalHeader .commonGlobalHeader-buttons {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
