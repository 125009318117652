<template>
  <div id="aboutMeHeadShowView">
    <h1>认识Pyta团队-跨越学习价值</h1>
    <p>
      自团队成立以来，Pyta以获得数千从业者的青睐，证明了我们对成功的承诺与实力
    </p>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#aboutMeHeadShowView {
  width: 100%;
  height: auto;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 225px;
}

#aboutMeHeadShowView h1 {
  font-size: 88px;
  line-height: 132px;
  text-align: center;
  margin-bottom: 20px;
}

#aboutMeHeadShowView p {
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
}
</style>
