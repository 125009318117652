import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "hotNews" }
const _hoisted_2 = { class: "hotNewsHeadShow" }
const _hoisted_3 = { class: "hotNewsContent" }

import HotNewsHeadShowView from "@/views/hotNewsView/HotNewsHeadShowView.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'HotNewsPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(HotNewsHeadShowView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ])
  ]))
}
}

})