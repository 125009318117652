import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "software" }
const _hoisted_2 = { class: "headShow" }
const _hoisted_3 = { class: "elementOne" }
const _hoisted_4 = { class: "elementTwo" }

import SoftwareHeadView from "@/views/transactionTrainingView/software/SoftwareHeadView.vue";
import SoftwareOneView from "@/views/transactionTrainingView/software/SoftwareOneView.vue";
import SoftwareTwoView from "@/views/transactionTrainingView/software/SoftwareTwoView.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionTrainingSoftwareView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SoftwareHeadView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(SoftwareOneView)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(SoftwareTwoView)
    ])
  ]))
}
}

})