<template>
  <div id="videoSchoolElementTwo">
    <div class="anchorColumn">
      <a-anchor direction="horizontal" :affix="false" @click="onChange">
        <a-anchor-link
          v-for="item in slideshowObject"
          v-bind:key="item.id"
          v-bind:href="'#videoSchoolTwoSlideshow-' + item.id"
          v-bind:title="'易天循实战系列' + item.id"
        />
      </a-anchor>
    </div>
    <div class="invitation-register">
      <img src="../../assets/images/gold.png" alt="" />
      <div>
        <h1>注册用户</h1>
        <h1>免费观看部分干货视频</h1>
      </div>
      <a-button>Go</a-button>
    </div>
    <div class="videSlideshow">
      <video-school-two-slideshow :slideshow-object="slideshowObject" />
    </div>
  </div>
</template>

<script setup lang="ts">
import VideoSchoolTwoSlideshow from "@/views/videoSchoolView/VideoSchoolTwoSlideshow.vue";
import { ref } from "vue";

interface slideshowInterface {
  id: number;
}

const slideshowObject = ref<slideshowInterface[]>([
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#videoSchoolElementTwo {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

#videoSchoolElementTwo .anchorColumn {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*粘性定位*/
  position: sticky;
  z-index: 999;
  top: 0;
  background-color: #f7faf8;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

:deep(.ant-anchor-wrapper-horizontal) {
  width: 1200px;
  height: 40px;
}

:deep(.ant-anchor) {
  height: 100%;
}

:deep(.ant-anchor-wrapper-horizontal .ant-anchor .ant-anchor-ink) {
  width: 120px !important;
  height: 4px;
  background-color: #de2727;
}

:deep(.ant-anchor-wrapper .ant-anchor .ant-anchor-link) {
  padding-inline: 25px 25px;
}

:deep(
    .ant-anchor-wrapper-horizontal .ant-anchor .ant-anchor-link:first-of-type
  ) {
  padding-inline: 25px 25px;
}

:deep(.ant-anchor-wrapper .ant-anchor .ant-anchor-link-title) {
  font-size: 16px;
  color: #675954;
}

:deep(
    .ant-anchor-wrapper
      .ant-anchor
      .ant-anchor-link-active
      > .ant-anchor-link-title
  ) {
  color: #675954;
  font-size: 16px;
}

:deep(
    .ant-anchor-wrapper
      .ant-anchor
      .ant-anchor-link-active
      > .ant-anchor-link-title-active
  ) {
  color: #2d211c;
}

#videoSchoolElementTwo .invitation-register {
  width: 1200px;
  height: 180px;
  background-image: url("../../assets/images/video_school_image3.png");
  background-size: cover;
  border-radius: 90px;
  margin-top: 110px;
  /*padding: 0 40px 0 200px;*/
  color: #ffa22c;
  position: relative;
  display: flex;
  align-items: center;
}

#videoSchoolElementTwo .invitation-register > img {
  position: absolute;
  left: 0;
  bottom: 0;
}

#videoSchoolElementTwo .invitation-register > div {
  position: absolute;
  left: 300px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.invitation-register h1 {
  font-size: 36px;
  text-align: left;
  line-height: 42px;
}

#videoSchoolElementTwo .invitation-register :deep(.ant-btn) {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ffc988;
  color: #a73003;
  font-size: 20px;
  border: 4px solid #fff3e0;
  position: absolute;
  right: 30px;
}

.videSlideshow {
  width: 1200px;
  height: auto;
}
</style>
