<!-- 热点新闻 右侧推荐视频 -->
<template>
  <div id="commonVideoRecommendationComponent">
    <p>推荐</p>
    <video controls="controls" v-for="i in 3" :key="i">
      <source src="../assets/video/video_landscape1.mp4" type="video/mp4" />
    </video>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

#commonVideoRecommendationComponent {
  width: 379px;
  height: 704px;
}

#commonVideoRecommendationComponent p {
  font-size: 32px;
  text-align: left;
}

#commonVideoRecommendationComponent video {
  width: 379px;
  height: 192px;
  margin-top: 32px;
  cursor: pointer;
}
</style>
