<template>
  <div id="merchantThreeView">
    <h1>做市商机构</h1>
    <p>
      这些做市商机构不仅在全球各大金融市场中扮演着重要角色，还通过先进的技术和交易策略为市场提供流动性，确保市场的有效运行和价格发现
    </p>
    <div class="imageGroup">
      <img src="../../../assets/images/citadel.png" alt="" />
      <img src="../../../assets/images/virtu.png" alt="" />
      <img src="../../../assets/images/sig.png" alt="" />
      <img src="../../../assets/images/optiverA.png" alt="" />
      <img src="../../../assets/images/imc.png" alt="" />
      <img src="../../../assets/images/flow_traders.png" alt="" />
      <img src="../../../assets/images/jane_street.png" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#merchantThreeView {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#merchantThreeView h1 {
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #2d211c;
}

#merchantThreeView p {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #675954;
  width: 790px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.imageGroup {
  width: 100%;
  height: 220px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.imageGroup > img:nth-child(1) {
  width: 614px;
  height: 48px;
}

.imageGroup > img:nth-child(2) {
  width: 158px;
  height: 60px;
}

.imageGroup > img:nth-child(3) {
  width: 141px;
  height: 48px;
}

.imageGroup > img:nth-child(4) {
  width: 136px;
  height: 48px;
}

.imageGroup > img:nth-child(5) {
  width: 254px;
  height: 48px;
}

.imageGroup > img:nth-child(6) {
  width: 595px;
  height: 32px;
}

.imageGroup > img:nth-child(7) {
  width: 171px;
  height: 48px;
}
</style>
