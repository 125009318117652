import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "vip" }
const _hoisted_2 = { class: "vipHeadShow" }
const _hoisted_3 = { class: "vipContent" }

import VipHeadShowView from "@/views/vipView/VipHeadShowView.vue";
import CommonFourCardComponent from "@/components/CommonFourCardComponent.vue";
import CommonInvitationRegisterComponent from "@/components/CommonInvitationRegisterComponent.vue";
import CommonLiveLessonComponent from "@/components/CommonLiveLessonComponent.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'VipPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(VipHeadShowView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "会员服务内容涵盖", -1)),
      _createVNode(CommonFourCardComponent),
      _cache[2] || (_cache[2] = _createElementVNode("h1", null, "会员专属教学视频", -1)),
      _createVNode(CommonInvitationRegisterComponent),
      _cache[3] || (_cache[3] = _createElementVNode("h1", null, "名师直播课", -1)),
      _createVNode(CommonLiveLessonComponent),
      _cache[4] || (_cache[4] = _createElementVNode("h1", null, "赠送Jigsaw交易系统", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("div", null, null, -1)),
      _createElementVNode("div", null, [
        _createVNode(_component_a_button, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("开通VIP")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})