<template>
  <div id="introducePage">
    <div class="introduceHeadShow">
      <introduce-head-view />
    </div>
    <div class="elementOne">
      <introduce-element-one-view />
    </div>
    <div class="elementTwo">
      <introduce-element-two-view />
    </div>
    <div class="elementThree">
      <introduce-element-three-view />
    </div>
    <div class="elementFour">
      <introduce-element-four-view />
    </div>
    <div class="elementFive">
      <introduce-element-five-view />
    </div>
    <div class="elementSix">
      <common-process-component
        title="如何成为学员和参与培训"
        :step-object="stepObject"
      />
    </div>
    <div class="homeSlideshow">
      <common-remark-slideshow-component
        :title="title"
        :display-style="false"
        :comment-title="commentTitle"
        :comment-content="commentContent"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import IntroduceHeadView from "@/views/introduceView/IntroduceHeadView.vue";
import IntroduceElementOneView from "@/views/introduceView/IntroduceElementOneView.vue";
import IntroduceElementTwoView from "@/views/introduceView/IntroduceElementTwoView.vue";
import IntroduceElementThreeView from "@/views/introduceView/IntroduceElementThreeView.vue";
import IntroduceElementFourView from "@/views/introduceView/IntroduceElementFourView.vue";
import IntroduceElementFiveView from "@/views/introduceView/introduceElementFiveView.vue";
import CommonProcessComponent from "@/components/CommonProcessComponent.vue";
import CommonRemarkSlideshowComponent from "@/components/CommonRemarkSlideshowComponent.vue";
import { ref } from "vue";

const title = "FAQ 问答";
const commentTitle = "Pyta课程包含那些内容";
const commentContent =
  "投资银行利用自有资金和融入资金直接参与证券市场交易并承担风险的业务,自营交易是指投资银行利用自有资金和融入资金直接参与证券市场交易并承担风险的一项业务。";

const stepObject = ref([
  {
    number: 1,
    content: "注册成为会员，领取会员学习资料，分配助教。",
    activeDisplay: "inline",
  },
  {
    number: 2,
    content: "助教根据学员情况交易经验，合理选择对应课程，并分配老师。",
    activeDisplay: "none",
  },
  {
    number: 3,
    content: "根据课程安排，每日与顶尖老师一起学习最新的交易策略和交易技巧。",
    activeDisplay: "none",
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#introducePage {
  width: 100%;
  height: auto;
}

#introducePage .introduceHeadShow {
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  background-image: url("../assets/images/pyta_head_image.png");
  background-size: cover;
  padding-top: 70px;
}

#introducePage .elementOne {
  width: 100%;
  height: 840px;
  display: flex;
  justify-content: center;
  background-color: white;
  padding-top: 100px;
}

#introducePage .elementTwo {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-color: white;
}

#introducePage .elementThree {
  width: 100%;
  height: 1023px;
  display: flex;
  justify-content: center;
  background-color: #faf8f7;
  padding-top: 120px;
}

#introducePage .elementFour {
  width: 100%;
  height: 900px;
  background-color: #42b983;
}

#introducePage .elementFive {
  width: 100%;
  height: 610px;
  display: flex;
  justify-content: center;
  background-color: white;
  padding-top: 90px;
}

#introducePage .elementSix {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-color: white;
  padding-top: 50px;
}

#introducePage .homeSlideshow {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #010818;
}
</style>
