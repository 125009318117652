import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "breadcrumb" }
const _hoisted_2 = { key: 0 }


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonBreadcrumbComponent',
  props: {
  routes: Object,
},
  setup(__props) {

// eslint-disable-next-line no-undef


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_breadcrumb, { routes: __props.routes }, {
      itemRender: _withCtx(({ route, paths }) => [
        (__props.routes.indexOf(route) === __props.routes.length - 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(route.breadcrumbName), 1))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: `/${paths.join('/')}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(route.breadcrumbName), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
      ]),
      _: 1
    }, 8, ["routes"])
  ]))
}
}

})