import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "homeBasicLayout" }

import CommonGlobalHeader from "@/components/CommonGlobalHeader.vue";
import CommonHomeFooter from "@/components/CommonHomeFooter.vue";
import CommonFloatMenuComponent from "@/components/CommonFloatMenuComponent.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeBasicLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_header, { class: "homeBasicLayout-header" }, {
          default: _withCtx(() => [
            _createVNode(CommonGlobalHeader)
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout_content, { class: "homeBasicLayout-content" }, {
          default: _withCtx(() => [
            _createVNode(CommonFloatMenuComponent),
            _createVNode(_component_router_view)
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout_footer, { class: "homeBasicLayout-footer" }, {
          default: _withCtx(() => [
            _createVNode(CommonHomeFooter)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})