<template>
  <div id="studyShow">
    <div class="studyShowHeadShow">
      <hot-news-head-show-view />
    </div>
    <div class="startScholar">
      <h1>明星学员</h1>
      <div>
        <study-show-panel-component
          v-for="(item, index) in panelObject"
          :key="index"
          :content-title="item.contentTitle"
          :people-name="item.peopleName"
          :time="item.time"
          :img-url="item.imgUrl"
        />
      </div>
    </div>
    <div class="studyShowContent">
      <study-show-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import HotNewsHeadShowView from "@/views/hotNewsView/HotNewsHeadShowView.vue";
import StudyShowPanelComponent from "@/components/StudyShowPanelComponent.vue";
import StudyShowView from "@/views/studyShow/StudyShowView.vue";

const panelObject = ref([
  {
    contentTitle: "明星学员 二期线下班",
    peopleName: "陈同学",
    time: "2024-11-21",
    imgUrl: require("@/assets/studyShow/11-21陈同学1.jpg"),
  },
  {
    contentTitle: "明星学员 二期线下班",
    peopleName: "王同学",
    time: "2024-11-24",
    imgUrl: require("@/assets/studyShow/2-21liu.jpg"),
  },
  {
    contentTitle: "明星学员 四期线下班",
    peopleName: "刘同学",
    time: "2025-02-18",
    imgUrl: require("@/assets/studyShow/2-21song.jpg"),
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#studyShow {
  width: 100%;
  height: auto;
}

#studyShow .studyShowHeadShow {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-image: url("../assets/images/hot_news_head_image.png");
  background-size: cover;
  padding-top: 70px;
  background-position: center;
}

#studyShow .startScholar {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
}

#studyShow .startScholar h1 {
  font-size: 48px;
  line-height: 72px;
  color: #2d211c;
  text-align: center;
  padding-bottom: 60px;
}

#studyShow .startScholar > div {
  width: 1200px;
  height: auto;
  display: flex;
  justify-content: space-between;
}

#studyShow .studyShowContent {
  width: 100%;
  height: auto;
}
</style>
