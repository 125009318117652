import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "articleDetails" }
const _hoisted_2 = { class: "detailsHead" }
const _hoisted_3 = { class: "detailsSummarize" }
const _hoisted_4 = { class: "detailsText" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "detailsSupplement" }

import {
  QqCircleFilled,
  WeiboCircleFilled,
  WechatFilled,
  WarningFilled,
} from "@ant-design/icons-vue";
import { ref } from "vue";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'StudyShowOneView',
  props: {
  name: String,
  imageUrl: String,
},
  setup(__props) {



const content = ref("");

return (_ctx: any,_cache: any) => {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(__props.name) + "的实战成果展示", 1),
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createElementVNode("p", null, "来源：" + _toDisplayString(__props.name), 1),
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "时间：2025-1-18 08:00", -1))
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("p", null, "分享：" + _toDisplayString(content.value), 1),
          _createVNode(_unref(QqCircleFilled), { style: {"color":"#fff2e4","background-color":"#faad08","border-radius":"50%"} }),
          _createVNode(_unref(WeiboCircleFilled), { style: {"color":"#ffe8e4","background-color":"#e54f00","border-radius":"50%"} }),
          _createVNode(_unref(WechatFilled), { style: {"color":"#2aae67"} })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_typography_paragraph, {
        ellipsis: _ctx.ellipsis ? { height: '12px' } : {}
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("span", null, "概述：", -1)
        ])),
        _: 1
      }, 8, ["ellipsis"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("img", {
        src: __props.imageUrl,
        alt: ""
      }, null, 8, _hoisted_5)
    ]),
    _createVNode(_component_a_divider, { style: {"height":"1px","background-color":"#ebebeb"} }),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", null, [
        _createVNode(_unref(WarningFilled), { style: {"color":"#ed0e0e"} }),
        _cache[2] || (_cache[2] = _createTextVNode(" 风险提示及免责条款：市场有风险，投资需谨慎。本文不构成个人投资建议，也未考虑到个别用户特殊的投资目标、财务状况或需要。用户应考虑本文中的任何意见、观点或结论是否符合其特定状况。据此投资，责任自负。 "))
      ])
    ])
  ]))
}
}

})