<template>
  <div id="introduceElementFive">
    <div class="text">
      <h1>Pyta巅峰自营交易学院<br />超乎您的期待</h1>
      <ul>
        <li>顶尖专家全方位学习支援，保证学习品质</li>
        <li>丰富多样的学习内容，满足各种学习需求</li>
        <li>每日持续更新，随时掌握最前沿知识</li>
        <li>即时直播课程，与专家面对面互动</li>
        <li>连续性学习路径，系统性提升能力</li>
        <li>严格把控课程品质，保证教学水准</li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#introduceElementFive {
  width: 100%;
  height: auto;
  background-image: url("../../assets/images/pyta_image5.png");
  overflow: hidden;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#introduceElementFive .text {
  width: 600px;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#introduceElementFive .text > h1 {
  font-size: 48px;
  line-height: 56px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  text-align: center;
  margin-bottom: 24px;
}

#introduceElementFive .text ul li {
  height: 28px;
  font-size: 20px;
  line-height: 23px;
  color: #2d211c;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  text-align: center;
  margin-bottom: 24px;
}
</style>
