import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import IntroducePage from "@/pages/IntroducePage.vue";
import HomeContentPage from "@/pages/HomeContentPage.vue";
import VideoSchoolPage from "@/pages/VideoSchoolPage.vue";
import HotNewsPage from "@/pages/HotNewsPage.vue";
import HotNewsContentView from "@/views/hotNewsView/HotNewsContentView.vue";
import HotHeadLineView from "@/views/hotNewsView/HotHeadLineView.vue";
import TransactionTrainingPage from "@/pages/TransactionTrainingPage.vue";
import TransactionTrainingLiveView from "@/views/transactionTrainingView/TransactionTrainingLiveView.vue";
import TransactionTrainingSoftwareView from "@/views/transactionTrainingView/TransactionTrainingSoftwareView.vue";
import TransactionTrainingMerchantView from "@/views/transactionTrainingView/TransactionTrainingMerchantView.vue";
import VipPage from "@/pages/VipPage.vue";
import AboutMePage from "@/pages/AboutMePage.vue";
import TargetCodePage from "@/pages/TargetCodePage.vue";
import ArticleListView from "@/views/article/ArticleListView.vue";
import ArticleContentView from "@/views/article/ArticleContentView.vue";
import VideoListView from "@/views/video/VideoListView.vue";
import VideoDetailsView from "@/views/video/VideoDetailsView.vue";
import MerchantDataPage from "@/pages/MerchantDataPage.vue";
import TutorDynamicsPage from "@/pages/TutorDynamicsPage.vue";
import StudyShowPage from "@/pages/StudyShowPage.vue";
import StudyShowDetailsView from "@/views/studyShow/StudyShowDetailsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "首页",
    component: HomeContentPage,
  },
  {
    path: "/tutorDynamics",
    name: "导师动态",
    component: TutorDynamicsPage,
  },
  {
    path: "/studyShow",
    name: "学员风采",
    component: StudyShowPage,
  },
  {
    path: "/studyShowDetails/:id",
    name: "学员风采展示",
    component: StudyShowDetailsView,
  },
  {
    path: "/pyta",
    name: "Pyta",
    component: IntroducePage,
  },
  {
    path: "/videoSchool",
    name: "视频学院",
    component: VideoSchoolPage,
  },
  {
    path: "/hotNews",
    name: "热点新闻",
    component: HotNewsPage,
    children: [
      {
        path: "/hotNews/news",
        name: "红点新闻",
        component: HotNewsContentView,
      },
      {
        path: "/hotNews/hot",
        name: "热点头条",
        component: HotHeadLineView,
      },
    ],
  },
  {
    path: "/transactionTraining",
    name: "交易培训",
    component: TransactionTrainingPage,
    children: [
      {
        path: "/transactionTraining/live",
        name: "直播",
        component: TransactionTrainingLiveView,
      },
      {
        path: "/transactionTraining/software",
        name: "交易软件",
        component: TransactionTrainingSoftwareView,
      },
      {
        path: "/transactionTraining/merchant",
        name: "市商逻辑",
        component: TransactionTrainingMerchantView,
      },
    ],
  },
  {
    path: "/targetCode",
    name: "指标代码",
    component: TargetCodePage,
  },
  {
    path: "/aboutMe",
    name: "关于我们",
    component: AboutMePage,
  },
  {
    path: "/vip",
    name: "VIP",
    component: VipPage,
  },
  {
    path: "/articleList",
    name: "文章列表",
    component: ArticleListView,
  },
  {
    path: "/articleContent",
    name: "文章内容页",
    component: ArticleContentView,
  },
  {
    path: "/videoList",
    name: "videoList",
    component: VideoListView,
    meta: {},
  },
  {
    path: "/videoDetailsView",
    name: "videoDetailsView",
    component: VideoDetailsView,
    meta: {},
  },
  {
    path: "/merchantData",
    name: "merchantData",
    component: MerchantDataPage,
    meta: {},
  },
  /*  {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/!* webpackChunkName: "about" *!/ "../views/AboutView.vue"),
    },*/
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
