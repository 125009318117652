<template>
  <div id="articleContent">
    <div></div>
    <div>
      <div class="articleContentLeft">
        <CommonBreadcrumbComponent :routes="routes" />
        <article-details-view />
      </div>
      <div class="articleContentRight">
        <CommonVideoRecommendationComponent />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonVideoRecommendationComponent from "@/components/CommonVideoRecommendationComponent.vue";
import CommonBreadcrumbComponent from "@/components/CommonBreadcrumbComponent.vue";
import ArticleDetailsView from "@/views/article/ArticleDetailsView.vue";
import { ref } from "vue";
// eslint-disable-next-line no-undef
defineProps({
  articleTitle: String,
  articleContent: String,
  articleParentTitle: String,
});

interface Route {
  path: string;
  breadcrumbName: string;
}

const routes = ref<Route[]>([
  {
    path: "/home",
    breadcrumbName: "首页",
  },
  {
    path: "#homeElementEightView",
    breadcrumbName: "学员焦点",
  },
  {
    path: "",
    breadcrumbName: "要做发牌的庄家，不做打牌的散户！",
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#articleContent {
  width: 100%;
  height: auto;
}

#articleContent > div:first-child {
  width: 100%;
  height: 70px;
  background-color: #010818;
}

#articleContent > div:last-child {
  width: 100%;
  height: auto;
  background-color: white;
  padding-top: 60px;
  padding-bottom: 78px;
  display: flex;
  justify-content: center;
}

.articleContentLeft {
  width: 774px;
  margin-right: 15px;
}

.articleContentRight {
  margin-left: 15px;
}
</style>
