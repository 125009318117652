import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "videoSchoolPage" }
const _hoisted_2 = { class: "videoSchoolHeadShow" }
const _hoisted_3 = { class: "elementOne" }
const _hoisted_4 = { class: "elementTwo" }
const _hoisted_5 = { class: "elementThree" }

import VideoSchoolHeadView from "@/views/videoSchoolView/VideoSchoolHeadView.vue";
import VideoSchoolElementOneView from "@/views/videoSchoolView/VideoSchoolElementOneView.vue";
import VideoSchoolElementTwoView from "@/views/videoSchoolView/VideoSchoolElementTwoView.vue";
import CommonProcessComponent from "@/components/CommonProcessComponent.vue";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoSchoolPage',
  setup(__props) {

const stepObject = ref([
  {
    number: 1,
    content: "点击视频课程后按购买按钮跳转到支付页面完成支付。",
    activeDisplay: "none",
  },
  {
    number: 2,
    content: "进入个人中心，我的课程栏目即可查看已购课程视频。",
    activeDisplay: "none",
  },
  {
    number: 3,
    content:
      "购买课程后联系在线客服或者助教，拉入小班级群，名师在线辅导，实现自我突破。",
    activeDisplay: "none",
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(VideoSchoolHeadView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(VideoSchoolElementOneView)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(VideoSchoolElementTwoView)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(CommonProcessComponent, {
        title: "如何购买及查看已购课程视频",
        "step-object": stepObject.value
      }, null, 8, ["step-object"])
    ])
  ]))
}
}

})