<template>
  <div id="merchantHeadView">
    <h2>Pyta直播学院</h2>
    <h1>做市商逻辑</h1>
    <p>
      了解做市商，学习做市商，做市商。<br />学习做市商逻辑，了解市场运作，理解市场深度、挂单行为以及盘口<br />发现数据的意义，发现短期交易机会。
    </p>
    <a-button>加入免费试训</a-button>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#merchantHeadView {
  width: 1200px;
  height: auto;
  color: white;
  margin-top: 127px;
}

#merchantHeadView h2 {
  font-size: 40px;
  line-height: 60px;
  text-align: left;
  margin-bottom: 16px;
}

#merchantHeadView h1 {
  font-size: 64px;
  line-height: 75px;
  text-align: left;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  margin-bottom: 24px;
}

#merchantHeadView p {
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  margin-bottom: 40px;
}

#merchantHeadView :deep(.ant-btn) {
  color: white;
  font-size: 16px;
  border: none;
  width: 200px;
  height: 42px;
  background-color: #de2727;
  border-radius: 8px;
}
</style>
