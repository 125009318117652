import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { id: "commonProcessComponent" }
const _hoisted_2 = { class: "step" }


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonProcessComponent',
  props: {
  title: String,
  stepObject: Object,
},
  setup(__props) {

// eslint-disable-next-line no-undef


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(__props.title), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.stepObject, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.number
        }, [
          _createElementVNode("h1", null, _toDisplayString(item.number), 1),
          _createElementVNode("p", null, _toDisplayString(item.content), 1),
          _createElementVNode("a", {
            href: "",
            target: "_blank",
            style: _normalizeStyle({ display: item.activeDisplay })
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("u", null, "立即注册", -1)
          ]), 4)
        ]))
      }), 128))
    ])
  ]))
}
}

})