import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/studyShow/2-21huang.jpg'


const _hoisted_1 = { id: "littleBox" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "introduceColumn" }


export default /*@__PURE__*/_defineComponent({
  __name: 'StudyShowPanelComponent',
  props: {
  contentTitle: String,
  peopleName: String,
  time: String,
  imgUrl: String,
},
  setup(__props) {

// eslint-disable-next-line no-undef


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: __props.imgUrl,
      alt: ""
    }, null, 8, _hoisted_2),
    _createElementVNode("h2", null, _toDisplayString(__props.contentTitle), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)),
        _createElementVNode("span", null, _toDisplayString(__props.peopleName), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString(__props.time), 1)
    ])
  ]))
}
}

})