<template>
  <div id="transactionTrainingOneView">
    <div class="ttOneTitle">
      <h1>导师直播语预告</h1>
      <p>针对性解决学习难题，让您的学习更加顺畅</p>
    </div>
    <div class="ttContent" v-for="item in ttContent" v-bind:key="item.id">
      <h1>{{ item.title }}</h1>
      <div>
        <common-panel-component
          :content-title="item.contentTitle"
          :people-name="item.peopleName"
          :time="item.time"
          v-for="i in 3"
          :key="i"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import CommonPanelComponent from "@/components/CommonPanelComponent.vue";

const ttContent = ref([
  {
    id: 1,
    title: "免费公开课预告",
    contentTitle: "课程标题",
    headImage: "",
    peopleName: "托尼老师",
    time: "2025-2-12 11:20",
  },
  {
    id: 2,
    title: "会员线上课预告",
    contentTitle: "课程标题",
    headImage: "",
    peopleName: "托尼老师",
    time: "2025-2-12 11:20",
  },
]);
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

#transactionTrainingOneView {
  width: 1200px;
  height: auto;
  padding-top: 100px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ttOneTitle {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ttOneTitle h1 {
  font-size: 48px;
  color: #2d211c;
  line-height: 72px;
  margin-bottom: 20px;
}

.ttOneTitle p {
  font-size: 24px;
  color: #675954;
  line-height: 28px;
}

.ttContent {
  width: 100%;
  height: auto;
  margin-top: 60px;
  margin-bottom: 16px;
}

.ttContent > h1 {
  font-size: 36px;
  line-height: 36px;
  text-align: left;
}

.ttContent > div {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
</style>
