import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "commentsSection" }

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ref } from "vue";

type Comment = Record<string, string>;


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonCommentsSectionComponent',
  setup(__props) {

dayjs.extend(relativeTime);

const data = [
  {
    actions: ["Reply to"],
    author: "易天循",
    avatar: "https://joeschmoe.io/api/v1/random",
    content:
      "全职交易者要去学发牌的庄家，而不是去学打牌的散户，你不但面临着久赌必输。还要时刻担心，那些做局的人。你要做的，就是去学会发牌，学会怎么去做局，搞清楚他们到底怎么弄的。",
    datetime: dayjs().subtract(1, "days"),
  },
  {
    actions: ["Reply to"],
    author: "易天循",
    avatar: "https://joeschmoe.io/api/v1/random",
    content:
      "现货黄金、MT4很多钱根本没流入市场，就像你去彩票店，老板给了你打了空单，你的钱根本没有进入市场，彩票店老板是在和你对赌。",
    datetime: dayjs().subtract(2, "days"),
  },
];

const comments = ref<Comment[]>([]);
const submitting = ref<boolean>(false);
const value = ref<string>("");
const handleSubmit = () => {
  if (!value.value) {
    return;
  }

  submitting.value = true;

  setTimeout(() => {
    submitting.value = false;
    comments.value = [
      {
        author: "Han Solo",
        avatar: "https://joeschmoe.io/api/v1/random",
        content: value.value,
        datetime: dayjs().fromNow(),
      },
      ...comments.value,
    ];
    value.value = "";
  }, 1000);
};

return (_ctx: any,_cache: any) => {
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_comment = _resolveComponent("a-comment")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_list, {
      class: "comment-list",
      header: `${data.length} 条评论`,
      "item-layout": "horizontal",
      "data-source": data
    }, {
      renderItem: _withCtx(({ item }) => [
        _createVNode(_component_a_list_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_comment, {
              author: item.author
            }, {
              content: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(item.content), 1)
              ]),
              datetime: _withCtx(() => [
                _createVNode(_component_a_tooltip, {
                  title: item.datetime.format('YYYY-MM-DD HH:mm:ss')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(item.datetime.fromNow()), 1)
                  ]),
                  _: 2
                }, 1032, ["title"])
              ]),
              _: 2
            }, 1032, ["author"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["header"]),
    (comments.value.length)
      ? (_openBlock(), _createBlock(_component_a_list, {
          key: 0,
          "data-source": comments.value,
          header: `${comments.value.length} ${
        comments.value.length > 1 ? 'replies' : 'reply'
      }`,
          "item-layout": "horizontal"
        }, {
          renderItem: _withCtx(({ item }) => [
            _createVNode(_component_a_list_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_comment, {
                  author: item.author,
                  avatar: item.avatar,
                  content: item.content,
                  datetime: item.datetime
                }, null, 8, ["author", "avatar", "content", "datetime"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["data-source", "header"]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_comment, null, {
      avatar: _withCtx(() => [
        _createVNode(_component_a_avatar, {
          src: "",
          alt: ""
        })
      ]),
      content: _withCtx(() => [
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_textarea, {
              value: value.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
              rows: 4
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              "html-type": "submit",
              loading: submitting.value,
              type: "primary",
              onClick: handleSubmit
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" 添加评论 ")
              ])),
              _: 1
            }, 8, ["loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})