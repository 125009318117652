<template>
  <div id="aboutMeTwoView">
    <h1>资深团队成员</h1>
    <div>
      <div v-for="i in 5" v-bind:key="i">
        <img src="../../assets/images/Rectangle%208@2x(1).png" alt="" />
        <h2>李青</h2>
        <p>总裁经理</p>
      </div>
    </div>
    <p>一起打造强力的Pyta</p>
    <a-button>加入我们</a-button>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#aboutMeTwoView {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

#aboutMeTwoView h1 {
  font-size: 48px;
  color: #2d2d2d;
  line-height: 72px;
  text-align: center;
  margin-bottom: 40px;
}

#aboutMeTwoView > div {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

#aboutMeTwoView > div div {
  width: 180px;
  height: 284px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#aboutMeTwoView > div div img {
  width: 180px;
  height: 180px;
  object-fit: contain;
  border-radius: 50%;
  background-color: #bdbdbd;
}

#aboutMeTwoView > div div h2 {
  font-size: 20px;
  color: #2d2d2d;
  line-height: 23px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 12px;
}

#aboutMeTwoView > div div p {
  font-size: 16px;
  color: #675954;
  line-height: 24px;
  text-align: center;
}

#aboutMeTwoView p {
  font-size: 16px;
  color: #de2727;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}

#aboutMeTwoView :deep(.ant-btn) {
  width: 196px;
  height: 42px;
  background-color: #de2727;
  border-radius: 8px;
  color: white;
  font-size: 16px;
}
</style>
