import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "merchant" }
const _hoisted_2 = { class: "headShow" }
const _hoisted_3 = { class: "elementOne" }
const _hoisted_4 = { class: "elementTwo" }
const _hoisted_5 = { class: "elementThree" }

import MerchantHeadView from "@/views/transactionTrainingView/merchant/MerchantHeadView.vue";
import MerchantTwoView from "@/views/transactionTrainingView/merchant/MerchantTwoView.vue";
import MerchantThreeView from "@/views/transactionTrainingView/merchant/MerchantThreeView.vue";
import MerchantOneView from "@/views/transactionTrainingView/merchant/MerchantOneView.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionTrainingMerchantView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(MerchantHeadView)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(MerchantOneView)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(MerchantTwoView)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(MerchantThreeView)
    ])
  ]))
}
}

})