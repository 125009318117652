import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode } from "vue"
import _imports_0 from '../assets/images/Rectangle 8@2x(1).png'


const _hoisted_1 = { id: "live-lesson" }

import { LockOutlined, FieldTimeOutlined } from "@ant-design/icons-vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'CommonLiveLessonComponent',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
      return _createElementVNode("div", { key: i }, [
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("div", null, [
            _createElementVNode("h1", null, "自营贸易在期货市场如何操作？"),
            _createElementVNode("p", null, "托尼老师")
          ], -1)),
          _createVNode(_unref(LockOutlined))
        ]),
        _createElementVNode("div", null, [
          _createVNode(_unref(FieldTimeOutlined)),
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "1月16日 19:00", -1))
        ])
      ])
    }), 64))
  ]))
}
}

})