import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "nonFarmView" }

import { ref } from "vue";
import TargetCodeData from "@/data/targetCodeData.json";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetCodeContentView',
  setup(__props) {

const activeKey = ref("1");

const columns = [
  {
    name: "名字",
    dataIndex: "name",
    key: "名字",
  },
  {
    title: "代码",
    dataIndex: "code",
    key: "代码",
  },
  {
    title: "交易所",
    dataIndex: "exchange",
    key: "交易所",
  },
  {
    title: "类型",
    dataIndex: "type",
    key: "类型",
  },
  {
    title: "最小波动",
    dataIndex: "minFluctuate",
    key: "最小波动",
  },
  {
    title: "乘数",
    dataIndex: "multiplier",
    key: "乘数",
  },
  {
    title: "每点价值",
    dataIndex: "valueNumber",
    key: "每点价值",
  },
  {
    title: "货币",
    dataIndex: "currency",
    key: "货币",
  },
];

const northAmericaData = TargetCodeData.northAmericaData;
const asiaData = TargetCodeData.asiaData;
const europeData = TargetCodeData.europeData;

return (_ctx: any,_cache: any) => {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", null, "数据行情", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", null, [
      _createTextVNode(" 本网页提供IB盈透证券的热门期货品种，涵盖美国、中国香港、新加坡、德国、日本等国家和地区，方便用户查询和使用。 "),
      _createElementVNode("br"),
      _createTextVNode("本站会不定期更新，您可收藏本页方便查看最新数据。 "),
      _createElementVNode("br"),
      _createTextVNode("请注意，部分期货品种代码一致、但乘数不同，代表不同规格的期货品种，搜索代码时，请注意选择合适的合约乘数。 ")
    ], -1)),
    _createVNode(_component_a_tabs, {
      activeKey: activeKey.value,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((activeKey).value = $event)),
      type: "card"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          key: "1",
          tab: "北美"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_table, {
              columns: columns,
              "data-source": _unref(northAmericaData),
              scroll: { y: 537 }
            }, null, 8, ["data-source"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_tab_pane, {
          key: "2",
          tab: "亚太"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_table, {
              columns: columns,
              "data-source": _unref(asiaData),
              scroll: { y: 537 }
            }, null, 8, ["data-source"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_tab_pane, {
          key: "3",
          tab: "欧洲"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_table, {
              columns: columns,
              "data-source": _unref(europeData),
              scroll: { y: 537 }
            }, null, 8, ["data-source"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["activeKey"])
  ]))
}
}

})