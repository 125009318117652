import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "merchantTwoView" }
const _hoisted_2 = ["src"]

import { ref } from "vue";
import CommonSignUpModuleComponent from "@/components/CommonSignUpModuleComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MerchantTwoView',
  setup(__props) {

const merchantObject = ref([
  {
    id: 1,
    imageUrl: "../../../assets/images/merchant_image1.png",
    introduce: "做市商逻辑培训课程内容介绍",
  },
  {
    id: 2,
    imageUrl: "../../../assets/images/merchant_image2.png",
    introduce: "做市商逻辑培训课程特色",
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", null, "做市商逻辑培训课程", -1)),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(merchantObject.value, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "merchant"
        }, [
          _createElementVNode("img", {
            src: item.imageUrl,
            alt: ""
          }, null, 8, _hoisted_2),
          _createElementVNode("div", null, [
            _createElementVNode("p", null, _toDisplayString(item.introduce), 1),
            _createVNode(_component_a_button, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("进入")
              ])),
              _: 1
            })
          ])
        ]))
      }), 128))
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("h2", null, "课程报名", -1)),
    _createVNode(CommonSignUpModuleComponent, { "background-color": "white" })
  ]))
}
}

})