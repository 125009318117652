import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "videoDetails" }
const _hoisted_2 = { class: "videoDetailsLeft" }
const _hoisted_3 = { class: "videoDetailsRight" }

import CommonVideoRecommendationComponent from "@/components/CommonVideoRecommendationComponent.vue";
import CommonBreadcrumbComponent from "@/components/CommonBreadcrumbComponent.vue";
import { ref } from "vue";
import VideoDetailsChildrenView from "@/views/video/VideoDetailsChildrenView.vue";
// eslint-disable-next-line no-undef
interface Route {
  path: string;
  breadcrumbName: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoDetailsView',
  props: {
  articleTitle: String,
  articleContent: String,
  articleParentTitle: String,
},
  setup(__props) {



const routes = ref<Route[]>([
  {
    path: "/home",
    breadcrumbName: "首页",
  },
  {
    //学员焦点
    path: "#homeElementEightView",
    breadcrumbName: "学员焦点",
  },
  {
    // 文章标题
    path: "",
    breadcrumbName: "articleTitle",
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1)),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(CommonBreadcrumbComponent, { routes: routes.value }, null, 8, ["routes"]),
        _createVNode(VideoDetailsChildrenView)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(CommonVideoRecommendationComponent)
      ])
    ])
  ]))
}
}

})