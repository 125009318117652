<template>
  <div id="videoList">
    <div class="videoHead">
      <h1>Pyta视频</h1>
    </div>
    <div class="videoBody">
      <common-breadcrumb-component :routes="routes" />
      <div>
        <video controls="controls">
          <source src="" type="video/mp4" />
        </video>
        <div>
          <video controls="controls">
            <source src="" type="video/mp4" />
          </video>
          <video controls="controls">
            <source src="" type="video/mp4" />
          </video>
        </div>
      </div>
      <all-video-and-filter-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonBreadcrumbComponent from "@/components/CommonBreadcrumbComponent.vue";
import AllVideoAndFilterView from "@/views/video/AllVideoAndFilterView.vue";
import { ref } from "vue";

interface Route {
  path: string;
  breadcrumbName: string;
}

const routes = ref<Route[]>([
  {
    path: "/videoSchool",
    breadcrumbName: "视频学院",
  },
  {
    path: "",
    breadcrumbName: "视频集",
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#videoList {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#videoList .videoHead {
  width: 100%;
  height: 370px;
  background-image: url("../../assets/images/video_head_image.png");
  background-size: cover;
  padding-top: 70px;
  background-color: #010818;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#videoList .videoHead h1 {
  color: white;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 4px;
  text-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  text-align: center;
}

#videoList .videoBody {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 100px;
}

#videoList .videoBody > div:nth-child(2) {
  width: 100%;
  height: 396px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 80px;
}

#videoList .videoBody > div > video {
  width: 789px;
  height: 100%;
  cursor: pointer;
}

#videoList .videoBody > div > div {
  width: 379px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#videoList .videoBody > div > div video {
  width: 100%;
  height: 186px;
  cursor: pointer;
}
</style>
