<template>
  <div id="introduceElementTwo">
    <div class="title">
      <h1>天循老师实盘做单记录</h1>
      <p>挑战4000美刀爆仓</p>
    </div>
    <div class="video">
      <div v-for="i in 6" v-bind:key="i">
        <video controls="controls">
          <source src="" type="video/mp4" />
        </video>
      </div>
    </div>
    <div class="invitation">
      <p>成为Pyta学员，与顶尖交易名师携手，实现长期稳定的盈利</p>
      <a href="" target="_blank"><u>更多视频</u>→</a>
    </div>
    <div class="invitation-register">
      <img src="../../assets/images/big_crown.png" alt="" />
      <div class="box">
        <div>
          <h1>新学员欢迎礼</h1>
          <p>注册即可获得vip专属学习资料及导师视频</p>
        </div>
        <a-button>立即注册</a-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#introduceElementTwo {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#introduceElementTwo .title {
  width: 790px;
  height: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 60px;
}

#introduceElementTwo .title > h1 {
  font-size: 48px;
  line-height: 72px;
  color: #2d211c;
  text-align: center;
  margin-bottom: 20px;
}

#introduceElementTwo .title > p {
  font-size: 24px;
  line-height: 28px;
  color: #ed0e0e;
  text-align: center;
}

#introduceElementTwo .video {
  width: 100%;
  height: 648px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

#introduceElementTwo .video > div {
  width: 32%;
  height: 45%;
}

#introduceElementTwo .video > div video {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#introduceElementTwo .invitation {
  width: 1200px;
  height: 70px;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

#introduceElementTwo .invitation p {
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #675954;
}

#introduceElementTwo .invitation a {
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #f53f3f;
}

#introduceElementTwo .invitation-register {
  width: 100%;
  height: 453px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

#introduceElementTwo .invitation-register img {
  object-fit: cover;
  position: absolute;
  z-index: 1;
  left: 80px;
}

#introduceElementTwo .box {
  width: 62.5%;
  height: 140px;
  background-image: url("../../assets/images/pyta_image1.png");
  background-size: cover;
  border-radius: 70px;
  padding: 0 100px 0 170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  position: absolute;
  left: 18.75%;
  top: 200px;
}

#introduceElementTwo .box > div h1 {
  font-size: 48px;
  line-height: 56px;
  text-align: left;
  margin-bottom: 20px;
}

#introduceElementTwo .box > div p {
  font-size: 20px;
  line-height: 23px;
  text-align: left;
}

:deep(.ant-btn) {
  width: 196px;
  height: 50px;
  color: white;
  background-color: #de2727;
  border-radius: 8px;
  border: none;
}
</style>
