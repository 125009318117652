<template>
  <div id="echarts" ref="chart"></div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";
import * as echarts from "echarts";

// eslint-disable-next-line no-undef
const props = defineProps({
  echartsDataObject: {
    type: Array,
    default: () => [],
  },
});
// eslint-disable-next-line vue/no-setup-props-destructure
const echartsData = props.echartsDataObject;

// 创建一个引用用于 ECharts 图表
const chart = ref(null);
let chartInstance = null;

// 初始化图表
const initChart = () => {
  chartInstance = echarts.init(chart.value);
  const options = {
    xAxis: {
      type: "category",
      data: [
        "24年10月",
        "24年11月",
        "24年12月",
        "25年1月",
        "25年2月",
        "25年3月",
        "25年4月",
      ],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: echartsData,
        type: "line",
      },
    ],
  };
  chartInstance.setOption(options);
};

// 处理窗口调整大小
const handleResize = () => {
  if (chartInstance) {
    chartInstance.resize();
  }
};

onMounted(() => {
  initChart();
  window.addEventListener("resize", handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
  if (chartInstance) {
    chartInstance.dispose(); // 清理图表实例
  }
});
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#echarts {
  width: 100%;
  height: 400px;
  background-color: transparent;
}
</style>
