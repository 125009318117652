<template>
  <div id="introduceElementThree">
    <div class="live">
      <div>
        <h1>名师直播课</h1>
        <a href="" target="_blank"><u>查看更多</u>→</a>
      </div>
      <common-live-lesson-component />
    </div>
    <div class="reportCard">
      <div>
        <h1>学员成绩单展示</h1>
        <a href="" target="_blank"><u>查看更多</u>→</a>
      </div>
      <div class="reportCardShow">
        <div v-for="i in 3" :key="i">
          <img src="../../assets/images/成绩单.jpg" alt="" />
          <p>{{ inspirationalPhrase }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonLiveLessonComponent from "@/components/CommonLiveLessonComponent.vue";

const inspirationalPhrase = "沉着、冷静、勇猛、有辨别、不自私。 --鲁迅";
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#introduceElementThree {
  width: 1200px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#introduceElementThree h1 {
  font-size: 48px;
  line-height: 48px;
  text-align: left;
  color: #2d211c;
}

#introduceElementThree a {
  font-size: 16px;
  color: #de2727;
  line-height: 19px;
  text-align: right;
}

#introduceElementThree .live {
  width: 100%;
  height: 385px;
}

#introduceElementThree .live > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}

#introduceElementThree .reportCard {
  width: 100%;
  height: 518px;
}

#introduceElementThree .reportCard > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}

#introduceElementThree .reportCardShow {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

#introduceElementThree .reportCardShow > div {
  width: 379px;
  height: 290px;
  border-radius: 24px;
  border: 1px solid #ebebeb;
  background-color: white;
  overflow: hidden;
}

#introduceElementThree .reportCardShow > div img {
  width: 100%;
  height: 189px;
  object-fit: cover;
  border-radius: 24px 24px 0 0;
}

#introduceElementThree .reportCardShow > div p {
  font-size: 16px;
  color: #675954;
  line-height: 19px;
  text-align: left;
  margin: 24px 24px 0 24px;
}
</style>
