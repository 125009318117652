import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonSignUpModuleComponent',
  props: {
  backgroundColor: String,
},
  setup(__props) {

// eslint-disable-next-line no-undef


return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input_search = _resolveComponent("a-input-search")!

  return (_openBlock(), _createElementBlock("div", {
    id: "signUpModule",
    style: _normalizeStyle({ backgroundColor: __props.backgroundColor })
  }, [
    _createVNode(_component_a_input, {
      value: _ctx.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      placeholder: "填写姓名",
      "allow-clear": ""
    }, null, 8, ["value"]),
    _createVNode(_component_a_input_search, {
      value: _ctx.value,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
      placeholder: "填写手机号",
      size: "large",
      onSearch: _ctx.onSearch
    }, {
      enterButton: _withCtx(() => [
        _createVNode(_component_a_button, null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("获取验证码")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "onSearch"]),
    _createVNode(_component_a_button, { class: "button" }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode("点击报名")
      ])),
      _: 1
    })
  ], 4))
}
}

})