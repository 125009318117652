import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"
import _imports_0 from '../../assets/images/Mask group@2x(1).png'


const _hoisted_1 = { id: "aboutMeFourView" }
const _hoisted_2 = {
  class: "custom-slick-arrow",
  style: {"left":"10px","z-index":"1"}
}
const _hoisted_3 = {
  class: "custom-slick-arrow",
  style: {"right":"10px"}
}

import CommonSignUpModuleComponent from "@/components/CommonSignUpModuleComponent.vue";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons-vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'AboutMeElementFourView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_a_carousel = _resolveComponent("a-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", null, "学员风采", -1)),
    _createVNode(_component_a_carousel, { arrows: "" }, {
      prevArrow: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(LeftCircleOutlined))
        ])
      ]),
      nextArrow: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(RightCircleOutlined))
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
          return _createElementVNode("div", {
            key: i,
            class: "contentControl"
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("p", null, " 田间大棚里，智能化种植、农业机器人大幅提高农业种植效率和水平；汽车生产线上，涂装机械臂按照“工艺规范”准确操作；商场里，智能导航导购等服务陆续推出……人工智能正逐步渗透到诸多传统产业领域，推动生产流程、管理模式、产品创新等转型升级，为传统产业注入“智慧”活力。同时，记者也深刻感知，技术的迭代升级，对技术的操作者——“人”的跨学科能力提出更高要求，急需既懂技术又熟悉行业知识的复合型人才，推动人工智能助力传统产业迈向高质量发展。 ", -1),
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1)
          ]))
        }), 64))
      ]),
      _: 1
    }),
    _createVNode(CommonSignUpModuleComponent, { "background-color": "#f5f5f5" })
  ]))
}
}

})