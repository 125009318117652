<!-- 四张卡片 -->
<template>
  <div id="four-card">
    <div>
      <img src="../assets/images/card_image1.png" alt="" />
      <p>专家直播讲课</p>
      <p>与专家零距离接触，快速消除你的知识盲区</p>
    </div>
    <div>
      <img src="../assets/images/card_image2.png" alt="" />
      <p>即时交易讯号</p>
      <p>为您的决策提供坚实支撑，增加您的投资自信</p>
    </div>
    <div>
      <img src="../assets/images/card_image3.png" alt="" />
      <p>每日市场分析与教学影片</p>
      <p>助您把握投资先机，消除市场变动的不确定感</p>
    </div>
    <div>
      <img src="../assets/images/card_image4.png" alt="" />
      <p>专属助教与会员特权</p>
      <p>针对性解决学习难题，让您的学习更加顺畅</p>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#four-card {
  width: 1200px;
  height: auto;
  display: flex;
  justify-content: space-between;
  color: white;
}

#four-card div {
  width: 276px;
  height: 312px;
  background: radial-gradient(0 47% at 61% 121%, #593528 6%, #2e1509 100%);
  border-radius: 24px;
  padding: 30px;
}

#four-card img {
  width: 60px;
  height: 60px;
  margin-top: 30px;
}

#four-card div p:first-of-type {
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  margin: 30px 0;
}

#four-card div p:last-child {
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}
</style>
