<!-- 热点头条 -->
<template>
  <div id="commonHotHeadLineComponent">
    <!-- 面包屑 -->
    <a-breadcrumb :routes="routes">
      <template #itemRender="{ route, paths }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ route.breadcrumbName }}
        </span>
        <router-link v-else :to="`/${paths.join('/')}`">
          {{ route.breadcrumbName }}
        </router-link>
      </template>
    </a-breadcrumb>
    <!-- 内容 -->
    <template v-for="i in 10" :key="i">
      <div
        v-for="item in headLineObject"
        :key="item.id"
        class="headLineContentBox"
      >
        <img src="../assets/images/people.jpg" alt="" />
        <div>
          <div>
            <router-link to="/articleContent"
              >{{ item.headLineTitle }}
            </router-link>
            <p>{{ item.headLineContent }}</p>
          </div>
          <div>
            <span>{{ item.headLineTime }}</span>
            <span>来源：{{ item.headLineSource }}</span>
          </div>
        </div>
      </div>
      <a-divider v-if="i != 10" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

interface Route {
  path: string;
  breadcrumbName: string;
}

const routes = ref<Route[]>([
  {
    path: "/home",
    breadcrumbName: "首页",
  },
  /*  {
      path: "/hotNews",
      breadcrumbName: "热点新闻",
    },*/
  {
    path: "/news",
    breadcrumbName: "热点头条",
  },
]);

// eslint-disable-next-line no-undef
defineProps({
  headLineObject: Object,
});
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

#commonHotHeadLineComponent {
  width: 790px;
  height: auto;
}

#commonHotHeadLineComponent :deep(.ant-breadcrumb) {
  font-size: 16px;
  height: 24px;
  margin-bottom: 8px;
}

#commonHotHeadLineComponent .headLineContentBox {
  width: 100%;
  height: 164px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

#commonHotHeadLineComponent .headLineContentBox img {
  width: 160px;
  height: 160px;
  border-radius: 12px;
}

#commonHotHeadLineComponent .headLineContentBox > div {
  width: 100%;
  height: 100%;
  margin: 0 16px 0 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.headLineContentBox > div > div:first-child {
  font-size: 16px;
}

.headLineContentBox > div > div:first-child a {
  color: #2d211c;
  line-height: 24px;
  text-align: left;
}

.headLineContentBox > div > div:first-child p {
  color: #675944;
  text-align: justify;
  line-height: 32px;
  margin-top: 12px;
}

.headLineContentBox > div > div:last-child {
  width: 239px;
  height: 21px;
  display: flex;
  justify-content: space-between;
}

.headLineContentBox > div > div:last-child span {
  font-size: 14px;
  color: #807d7c;
  line-height: 21px;
  text-align: justify;
}

:deep(.ant-divider) {
  border: 1px #e0e0e0 solid;
  margin-top: 19px;
  padding: 0;
  margin-bottom: 0;
}
</style>
