<!-- Pyta全球合作名师 -->
<template>
  <div id="commonCharactersIntroComponent">
    <h1>Pyta全球合作名师</h1>
    <p>
      汇聚多为来自全亚洲的顶尖讲师，为投资人带来全面的交易自营经验，帮助各位在交易道路上快速成长。
    </p>
    <div class="celebrity">
      <div class="littleCelebrity" v-for="i in 5" :key="i">
        <img src="../assets/images/Rectangle%208@2x.png" alt="" />
        <div>
          <h2>李新萍</h2>
          <p>16 课程 | 10 直播</p>
        </div>
      </div>
    </div>
    <div class="invitation">
      <p>成为Pyta全球合作导师，与顶尖交易名师携手，共创全球一流自营交易学院</p>
      <a href="https://baidu.com" target="_blank"><u>立即加入Pyta</u>→</a>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

#commonCharactersIntroComponent {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#commonCharactersIntroComponent h1 {
  font-size: 48px;
  line-height: 72px;
  margin-bottom: 20px;
}

#commonCharactersIntroComponent p {
  font-size: 16px;
  line-height: 19px;
}

.celebrity {
  width: 100%;
  height: 337px;
  margin: 60px 0;
  display: flex;
  justify-content: space-between;
}

.littleCelebrity {
  width: 221px;
  height: 100%;
  border: 1px #ebebeb solid;
  border-radius: 24px;
  overflow: hidden;
  background-color: white;
}

.littleCelebrity > img {
  width: 100%;
  height: 221px;
  border-radius: 24px 24px 0 0;
  object-fit: cover;
}

.littleCelebrity > div {
  margin: 24px 20px 0 20px;
}

.littleCelebrity h2 {
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  margin-bottom: 12px;
}

.littleCelebrity p {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}

.invitation {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 19px;
}

.invitation p {
  color: #675954;
}
</style>
