<template>
  <div id="introduceHeadShow">
    <h2>Pyta巅峰自营交易</h2>
    <h1>专注自营交易</h1>
    <h1>正规交易系统化培训</h1>
    <h1>场内交易训练</h1>
    <p>
      行业理念领先，严格风控和高效策略，帮助全职交易员构建稳健的<br />交易系统实现长期稳定的盈利。
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#introduceHeadShow {
  width: 1200px;
  height: auto;
  color: white;
  margin-top: 92px;
}

#introduceHeadShow h2 {
  font-size: 40px;
  line-height: 60px;
  text-align: left;
}

#introduceHeadShow h1 {
  font-size: 64px;
  line-height: 75px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  text-align: left;
  margin-top: 16px;
  margin-bottom: 24px;
}

#introduceHeadShow p {
  font-size: 20px;
  line-height: 23px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  text-align: left;
}
</style>
