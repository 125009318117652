<template>
  <div id="softwareOneView">
    <h1>Jigsaw演示</h1>
    <p>
      实战演示，体验交易系统无延迟，实时价格，精确到毫秒的购入及卖出价格，跳过中间商赚差价
    </p>
    <div class="video"></div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

#softwareOneView {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#softwareOneView h1 {
  font-size: 48px;
  line-height: 72px;
  text-align: center;
}

#softwareOneView p {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #675954;
  width: 790px;
  margin: 20px 0 60px 0;
}

.video {
  width: 100%;
  height: 674px;
  background-color: #c59883;
}
</style>
