<template>
  <div id="hotNewsHeadShowView">
    <div class="hotNewsHeadShowViewText">
      <h2>学员风采</h2>
      <h1>实时分享</h1>
      <h1>开启财富之旅</h1>
      <p>实时更新市场快讯，助你掌握市场动态，<br />解锁交易潜力。</p>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
#hotNewsHeadShowView {
  width: 1200px;
  height: auto;
  color: white;
  margin-top: 100px;
}

.hotNewsHeadShowViewText {
  letter-spacing: 2px;
  line-height: 1.2;
}

.hotNewsHeadShowViewText h2 {
  font-size: 40px;
}

.hotNewsHeadShowViewText h1 {
  font-size: 64px;
}

.hotNewsHeadShowViewText p {
  font-size: 20px;
}
</style>
