<template>
  <div id="introduceElementFour">
    <p>线下课堂记录</p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#introduceElementFour {
  height: 100%;
  overflow: hidden;
  color: white;
  font-size: 48px;
  line-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
