import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "slideshow-title" }
const _hoisted_3 = { class: "slideshow" }
const _hoisted_4 = {
  class: "custom-slick-arrow",
  style: {"left":"10px","z-index":"1"}
}
const _hoisted_5 = {
  class: "custom-slick-arrow",
  style: {"right":"10px"}
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "slideshowCurve" }

import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons-vue";
import CommonEChartsComponent from "@/components/CommonEChartsComponent.vue";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'StudyShowContentView',
  props: {
  headLineObject: Object,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_carousel = _resolveComponent("a-carousel")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.headLineObject, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      key: item.id,
      id: 'studyShowSlideshow-' + item.id
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: '/studyShowDetails/' + item.id
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.term) + "：" + _toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1032, ["to"]),
        _createElementVNode("p", null, [
          _cache[0] || (_cache[0] = _createElementVNode("span", null, "做单记录：", -1)),
          _createTextVNode(_toDisplayString(item.describe), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_carousel, {
          arrows: "",
          dots: false,
          "slides-to-show": 4
        }, {
          prevArrow: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(LeftCircleOutlined))
            ])
          ]),
          nextArrow: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(RightCircleOutlined))
            ])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.image, (img, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "slideshowDiv",
                key: index
              }, [
                _createVNode(_component_a_image, {
                  src: img,
                  alt: ""
                }, null, 8, ["src"])
              ]))
            }), 128)),
            (item.image.length < 4)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6))
              : _createCommentVNode("", true),
            (item.image.length < 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7))
              : _createCommentVNode("", true),
            (item.image.length < 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024),
        _createElementVNode("div", _hoisted_9, [
          _cache[1] || (_cache[1] = _createElementVNode("h1", null, "学员成绩动态曲线", -1)),
          _createVNode(CommonEChartsComponent, {
            "echarts-data-object": item.echartsDataArray
          }, null, 8, ["echarts-data-object"])
        ]),
        _createVNode(_component_a_divider)
      ])
    ], 8, _hoisted_1))
  }), 128))
}
}

})