import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"
import _imports_0 from '../assets/images/Rectangle 8@2x(2).png'


const _hoisted_1 = { id: "remarkSlideshow" }
const _hoisted_2 = { style: {"left":"200px","z-index":"1"} }
const _hoisted_3 = { style: {"right":"200px"} }

import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons-vue";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'CommonRemarkSlideshowComponent',
  props: {
  title: String,
  commentTitle: String,
  commentContent: String,
  displayStyle: Boolean,
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_a_carousel = _resolveComponent("a-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(__props.title), 1),
    _createVNode(_component_a_carousel, {
      arrows: "",
      "slides-to-show": 3
    }, {
      prevArrow: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(ArrowLeftOutlined))
        ])
      ]),
      nextArrow: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(ArrowRightOutlined))
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
          return _createElementVNode("div", {
            class: "remark",
            key: i
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "",
                style: _normalizeStyle({ display: __props.displayStyle ? 'unset' : 'none' })
              }, null, 4),
              _createElementVNode("span", null, _toDisplayString(__props.commentTitle), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(__props.commentContent), 1)
          ])
        }), 64))
      ]),
      _: 1
    })
  ]))
}
}

})