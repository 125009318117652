<template>
  <div id="articleList">
    <div class="articleListHead">
      <h1>学员焦点</h1>
    </div>
    <div class="articleListBody">
      <div>
        <common-hot-headline-component :head-line-object="headLineObject" />
      </div>
      <div>
        <CommonVideoRecommendationComponent />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import CommonHotHeadlineComponent from "@/components/CommonHotHeadlineComponent.vue";
import CommonVideoRecommendationComponent from "@/components/CommonVideoRecommendationComponent.vue";
import { ref } from "vue";

interface HeadLineObjects {
  id: number;
  headLineTitle: string;
  headLineContent: string;
  imageUrl: string;
  headLineTime: string;
  headLineSource: string;
}

const headLineObject = ref<HeadLineObjects[]>([
  {
    id: 0,
    headLineTitle:
      "经济学家高善文：25-59岁人口失业率创新低，简单讨论失业率非常不完整",
    headLineContent:
      "在核污染水处置问题上，日方应正视国际社会的合理关切，本着对全球海洋环境和公众健康负责任的态度，同包括周边邻国在内的利益攸关方开展充分、有意义的协商。日方继续强推核污染水排海，只会激起更强烈的反对。",
    imageUrl: "../../assets/images/people.jpg",
    headLineTime: "2025-1-18 18:00:00",
    headLineSource: "今日头条",
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#articleList {
  width: 100%;
  height: auto;
}

#articleList .articleListHead {
  width: 100%;
  height: 300px;
  background-image: url("../../assets/images/article_list_head.png");
  background-size: cover;
  padding-top: 70px;
  background-color: #010818;
  background-origin: content-box;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

#articleList .articleListHead h1 {
  color: white;
  line-height: 72px;
  text-align: center;
  letter-spacing: 4px;
  text-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  font-size: 48px;
}

#articleList .articleListBody {
  width: 100%;
  height: auto;
  padding: 80px 0;
  display: flex;
  justify-content: center;
}

.articleListBody > div:first-child {
  margin-right: 15px;
}

.articleListBody > div:last-child {
  margin-left: 15px;
}
</style>
