<!-- 公共底部 -->
<template>
  <div id="commonHomeFooter">
    <div class="footerNavigation">
      <div>
        <h1>Pyta自营交易学院</h1>
        <p>您的财经信息一站式平台</p>
      </div>
      <nav>
        <ul>
          <li v-for="(item, index) in items" :key="index">
            <router-link to="">{{ item.title }}</router-link>
            <!--<router-link :to="item.key">{{ item.title }}</router-link>-->
            <ul v-if="item.children !== null">
              <li v-for="(x, xIndex) in item.children" :key="xIndex">
                <!--<router-link :to="x.key">{{ x.title }}</router-link>-->
                <router-link to="">{{ x.title }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <div class="brand">
      <FacebookOutlined v-for="i in 5" v-bind:key="i" />
    </div>
    <div class="footerCollapse">
      <div>
        <span @click="adfa">友情链接</span>
        <DownOutlined style="margin-left: 8px" />
      </div>
      <nav>
        <ul>
          <li v-for="(item, index) in items" :key="index">
            <router-link :to="item.key">{{ item.title }}</router-link>
          </li>
        </ul>
      </nav>
      <div style="display: none">
        <a href="https://baidu.com">百度</a>
      </div>
    </div>
    <a-divider />
    <p>&copy;Copyright 2025,All Rights Reserved by PYTA</p>
  </div>
</template>

<script setup lang="ts">
import { FacebookOutlined, DownOutlined } from "@ant-design/icons-vue";
import { ref } from "vue";

interface ItemTypeInterface {
  key: string;
  label: string;
  title: string;
  children: [];
}

const items = ref<ItemTypeInterface[]>([
  {
    key: "/pyta",
    label: "Pyta",
    title: "Pyta",
    children: [],
  },
  {
    key: "/videoSchool",
    label: "视频学院",
    title: "视频学院",
    children: [],
  },
  {
    key: "/targetCode",
    label: "指标代码",
    title: "指标代码",
    children: [],
  },
  {
    key: "/hotNews/hot",
    label: "热点新闻",
    title: "热点新闻",
    children: [
      /*{
        key: "/hotNews/news",
        label: "红点新闻",
        title: "红点新闻",
      },
      {
        key: "/hotNews/hot",
        label: "热点头条",
        title: "热点头条",
      },*/
    ],
  },
  {
    key: "/transactionTraining/live",
    label: "交易培训",
    title: "交易培训",
    children: [
      {
        key: "/transactionTraining/live",
        label: "直播",
        title: "直播",
      },
      {
        key: "/transactionTraining/software",
        label: "交易软件",
        title: "交易软件",
      },
      {
        key: "/transactionTraining/merchant",
        label: "市商逻辑",
        title: "市商逻辑",
      },
    ],
  },
  {
    key: "/aboutMe",
    label: "关于我们",
    title: "关于我们",
    children: [],
  },
  {
    key: "/vip",
    label: "VIP",
    title: "VIP",
    children: [],
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#commonHomeFooter {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

#commonHomeFooter .footerNavigation {
  width: 1200px;
  height: auto;
  display: flex;
  justify-content: space-between;
  color: white;
}

#commonHomeFooter .footerNavigation > div:first-child h1 {
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  margin-bottom: 16px;
}

#commonHomeFooter .footerNavigation > div:first-child p {
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

#commonHomeFooter .footerNavigation > nav {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  color: white;
}

#commonHomeFooter .footerNavigation > nav > ul {
  list-style-type: none;
  display: flex;
}

#commonHomeFooter .footerNavigation > nav li {
  list-style-type: none;
}

#commonHomeFooter .footerNavigation > nav > ul > li > ul li {
  margin-top: 16px;
}

#commonHomeFooter .footerNavigation > nav a {
  margin-left: 40px;
  color: white;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

#commonHomeFooter .brand {
  width: 1200px;
  height: 30px;
  display: flex;
  align-items: center;
  margin-top: 32px;
}

#commonHomeFooter .brand :deep(.anticon) {
  width: 28px;
  height: 28px;
  font-size: 28px;
  background-color: white;
  margin-right: 10px;
}

#commonHomeFooter .footerCollapse {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  margin-top: 12px;
}

#commonHomeFooter .footerCollapse > div:first-child {
  width: 100%;
  height: 24px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
  color: white;
}

#commonHomeFooter .footerCollapse > div:first-child span:first-child {
  color: white;
  font-size: 19px;
  line-height: 48px;
  cursor: pointer;
}

#commonHomeFooter .footerCollapse > nav > ul {
  list-style-type: none;
  display: flex;
}

#commonHomeFooter .footerCollapse > nav li {
  list-style-type: none;
}

#commonHomeFooter .footerCollapse > nav a {
  margin-right: 40px;
  color: #bcbcbc;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

#commonHomeFooter :deep(.ant-divider) {
  width: 1200px;
  border: 1px white solid;
  opacity: 0.1;
  margin-top: 40px;
}

#commonHomeFooter > p:last-child {
  height: 20px;
  font-size: 14px;
  text-align: center;
  color: white;
  line-height: 16px;
  margin: 40px 0;
}
</style>
