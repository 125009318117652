import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "transactionTrainingOneView" }

import { ref } from "vue";
import CommonPanelComponent from "@/components/CommonPanelComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionTrainingOneView',
  setup(__props) {

const ttContent = ref([
  {
    id: 1,
    title: "免费公开课预告",
    contentTitle: "课程标题",
    headImage: "",
    peopleName: "托尼老师",
    time: "2025-2-12 11:20",
  },
  {
    id: 2,
    title: "会员线上课预告",
    contentTitle: "课程标题",
    headImage: "",
    peopleName: "托尼老师",
    time: "2025-2-12 11:20",
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "ttOneTitle" }, [
      _createElementVNode("h1", null, "导师直播语预告"),
      _createElementVNode("p", null, "针对性解决学习难题，让您的学习更加顺畅")
    ], -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ttContent.value, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "ttContent",
        key: item.id
      }, [
        _createElementVNode("h1", null, _toDisplayString(item.title), 1),
        _createElementVNode("div", null, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
            return _createVNode(CommonPanelComponent, {
              "content-title": item.contentTitle,
              "people-name": item.peopleName,
              time: item.time,
              key: i
            }, null, 8, ["content-title", "people-name", "time"])
          }), 64))
        ])
      ]))
    }), 128))
  ]))
}
}

})