<template>
  <div id="videoSchoolElementOne">
    <div class="title">
      <img src="../../assets/images/Rectangle%208@2x(2).png" alt="" />
      <h1>天循老师直播内容分享</h1>
    </div>
    <div class="video">
      <div v-for="i in 6" :key="i">
        <video controls="controls">
          <source src="" type="video/mp4" />
        </video>
      </div>
    </div>
    <div class="invitation">
      <p>成为Pyta学员，与顶尖交易名师携手，实现长期稳定的盈利</p>
      <router-link to="/videoList"><u>更多视频</u>→</router-link>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#videoSchoolElementOne {
  width: 1294px;
  height: 690px;
  background-image: url("../../assets/images/video_school_bgImage.png");
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#videoSchoolElementOne .title {
  width: 790px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 44px;
  overflow: hidden;
  padding: 0 108px;
}

#videoSchoolElementOne .title img {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  object-fit: cover;
}

#videoSchoolElementOne .title h1 {
  font-size: 48px;
  color: white;
  line-height: 72px;
  text-align: center;
}

#videoSchoolElementOne .video {
  width: 1200px;
  height: 408px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

#videoSchoolElementOne .video div {
  width: 384px;
  height: 192px;
  border-radius: 24px;
}

#videoSchoolElementOne .video div video {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#videoSchoolElementOne .invitation {
  width: 1200px;
  height: 70px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-top: 41px;
}

#videoSchoolElementOne .invitation a {
  color: white;
}
</style>
