import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { id: "commonHomeFooter" }
const _hoisted_2 = { class: "footerNavigation" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "brand" }
const _hoisted_5 = { class: "footerCollapse" }

import { FacebookOutlined, DownOutlined } from "@ant-design/icons-vue";
import { ref } from "vue";

interface ItemTypeInterface {
  key: string;
  label: string;
  title: string;
  children: [];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonHomeFooter',
  setup(__props) {

const items = ref<ItemTypeInterface[]>([
  {
    key: "/pyta",
    label: "Pyta",
    title: "Pyta",
    children: [],
  },
  {
    key: "/videoSchool",
    label: "视频学院",
    title: "视频学院",
    children: [],
  },
  {
    key: "/targetCode",
    label: "指标代码",
    title: "指标代码",
    children: [],
  },
  {
    key: "/hotNews/hot",
    label: "热点新闻",
    title: "热点新闻",
    children: [
      /*{
        key: "/hotNews/news",
        label: "红点新闻",
        title: "红点新闻",
      },
      {
        key: "/hotNews/hot",
        label: "热点头条",
        title: "热点头条",
      },*/
    ],
  },
  {
    key: "/transactionTraining/live",
    label: "交易培训",
    title: "交易培训",
    children: [
      {
        key: "/transactionTraining/live",
        label: "直播",
        title: "直播",
      },
      {
        key: "/transactionTraining/software",
        label: "交易软件",
        title: "交易软件",
      },
      {
        key: "/transactionTraining/merchant",
        label: "市商逻辑",
        title: "市商逻辑",
      },
    ],
  },
  {
    key: "/aboutMe",
    label: "关于我们",
    title: "关于我们",
    children: [],
  },
  {
    key: "/vip",
    label: "VIP",
    title: "VIP",
    children: [],
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", null, [
        _createElementVNode("h1", null, "Pyta自营交易学院"),
        _createElementVNode("p", null, "您的财经信息一站式平台")
      ], -1)),
      _createElementVNode("nav", null, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createVNode(_component_router_link, { to: "" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1024),
              (item.children !== null)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (x, xIndex) => {
                      return (_openBlock(), _createElementBlock("li", { key: xIndex }, [
                        _createVNode(_component_router_link, { to: "" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(x.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
        return _createVNode(_unref(FacebookOutlined), { key: i })
      }), 64))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", null, [
        _createElementVNode("span", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.adfa && _ctx.adfa(...args)))
        }, "友情链接"),
        _createVNode(_unref(DownOutlined), { style: {"margin-left":"8px"} })
      ]),
      _createElementVNode("nav", null, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createVNode(_component_router_link, {
                to: item.key
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { style: {"display":"none"} }, [
        _createElementVNode("a", { href: "https://baidu.com" }, "百度")
      ], -1))
    ]),
    _createVNode(_component_a_divider),
    _cache[3] || (_cache[3] = _createElementVNode("p", null, "©Copyright 2025,All Rights Reserved by PYTA", -1))
  ]))
}
}

})