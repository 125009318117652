<template>
  <div id="homeElementEightView">
    <h1>热门文章</h1>
    <div>
      <common-article-module-component
        article-type="学员焦点"
        :article-object="articleFocusObject"
      />
      <a-divider type="vertical" />
      <common-article-module-component
        article-type="热门观点"
        :article-object="articlePointObject"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonArticleModuleComponent from "@/components/CommonArticleModuleComponent.vue";
import { ref } from "vue";

interface article {
  articleTitle: string;
  articleContent: string;
  articleTime: string;
}

const articleFocusObject = ref<article[]>([
  {
    articleTitle: "要做发牌的庄家，不做打牌的散户！",
    articleContent:
      "全职交易者要去学发牌的庄家，而不是去学打牌的散户，你不但面临着久赌必输。" +
      "还要时刻担心，那些做局的人。你要做的，就是去学会发牌。" +
      "学会怎么去做局，搞清楚他们到底怎么弄的,那你得知道做市商赚钱的逻辑，对吧？",
    articleTime: "2025-1-16 08:00",
  },
  {
    articleTitle: "中国交易者困境！",
    articleContent:
      "中国的交易者能不痛苦吗？一堆老师给你瞎分析基本面，一堆假消息。" +
      "让你不要频繁交易，要你长时间持仓。方向反了，又告诉你，是你心态不好。" +
      "我都亏成这个样子了，你还想让我心态好我都看不见数据，你让我去做单，不是亏死了吗？",
    articleTime: "2025-1-16 08:00",
  },
  {
    articleTitle: "高频交易的优势！",
    articleContent:
      "一周我能做1000笔，你就是做不了,所有老师都在跟你说，不能太高频。" +
      "高频会亏的，手续费太贵了,因为你缺乏数据，所以你胜率不高。",
    articleTime: "2025-1-16 08:00",
  },
]);

const articlePointObject = ref<article[]>([
  {
    articleTitle: "谨慎去做全职交易！",
    articleContent:
      "做黄金和外汇你就不要辞职了我们的学员里面90%都是不以交易为生的，" +
      "交易只是他们的一个副业，人家有的 有连锁店，有的已经退休了，" +
      "或者人家觉得大环境不好，不敢乱投钱，决定暂时来看看订单流怎么做",
    articleTime: "2025-1-16 08:00",
  },
  {
    articleTitle: "先赔，才能后赚钱！",
    articleContent:
      "不赔钱，是不是什么都学不会？不肉疼，你永远都不会拿交易当会儿事你觉得这两句话，对吗？" +
      "对个P，他的逻辑是，一百万身家，90万弄进去重仓猛干，浮盈加仓。" +
      "不管是输是赢，从人生的大起大落，去感知，去得道，然后变的更厉害",
    articleTime: "2025-1-16 08:00",
  },
  {
    articleTitle: "安全出金才重要！",
    articleContent:
      "假如你澳门赢了800万不想玩了，会出现什么后果？" +
      "你以为的赌场，你赢钱了，冲出来几个黑衣男子手里拿着武器，然后跟你给说：“还没人能从我这里，把钱带走。”" +
      "实际的情况是，你赌赢的是其他赌客的钱，和人家一点关系没有，人家还收了你的手续费。",
    articleTime: "2025-1-16 08:00",
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#homeElementEightView {
  width: 1200px;
  height: auto;
}

#homeElementEightView > h1 {
  height: 72px;
  font-size: 48px;
  color: #2d211c;
  line-height: 72px;
  text-align: center;
  margin-bottom: 50px;
}

#homeElementEightView > div {
  width: 100%;
  height: 607px;
  display: flex;
  justify-content: space-between;
}

#homeElementEightView :deep(.ant-divider) {
  width: 1px;
  height: 607px;
  background-color: #ebebeb;
}
</style>
