<template>
  <div id="merchantTwoView">
    <h1>做市商逻辑培训课程</h1>
    <div>
      <div v-for="item in merchantObject" :key="item.id" class="merchant">
        <img v-bind:src="item.imageUrl" alt="" />
        <div>
          <p>{{ item.introduce }}</p>
          <a-button>进入</a-button>
        </div>
      </div>
    </div>
    <h2>课程报名</h2>
    <common-sign-up-module-component background-color="white" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import CommonSignUpModuleComponent from "@/components/CommonSignUpModuleComponent.vue";

const merchantObject = ref([
  {
    id: 1,
    imageUrl: "../../../assets/images/merchant_image1.png",
    introduce: "做市商逻辑培训课程内容介绍",
  },
  {
    id: 2,
    imageUrl: "../../../assets/images/merchant_image2.png",
    introduce: "做市商逻辑培训课程特色",
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#merchantTwoView {
  width: 1200px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#merchantTwoView h1 {
  font-size: 48px;
  line-height: 72px;
  color: #2d211c;
  text-align: center;
}

#merchantTwoView h2 {
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 33px;
}

#merchantTwoView > div:nth-child(2) {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin: 60px 0 102px 0;
}

.merchant {
  width: 570px;
  height: 416px;
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  border: 1px #ebebeb solid;
  overflow: hidden;
}

.merchant > img {
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.merchant > div {
  margin: 32px 24px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.merchant p {
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  color: #2d211c;
}

.merchant :deep(.ant-btn) {
  width: 56px;
  height: 32px;
  background-color: #de2727;
  border-radius: 4px;
  color: white;
  font-size: 16px;
}
</style>
