import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "videoSchoolElementTwo" }
const _hoisted_2 = { class: "anchorColumn" }
const _hoisted_3 = { class: "videSlideshow" }

import { ref } from "vue";
import VideoSchoolTwoSlideshow from "@/views/videoSchoolView/VideoSchoolTwoSlideshow.vue";

interface slideshowInterface {
  id: number;
  videoArray: Array<any>;
  describe: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TutorDynamicsTwoView',
  setup(__props) {

const slideshowObject = ref<slideshowInterface[]>([
  {
    id: 10,
    videoArray: [
      require("@/assets/video/video_landscape10-1.mp4"),
      require("@/assets/video/video_landscape10-2.mp4"),
      require("@/assets/video/video_landscape10-3.mp4"),
    ],
    describe: "24年10月",
  },
  {
    id: 11,
    videoArray: [
      require("@/assets/video/video_landscape11-1.mp4"),
      require("@/assets/video/video_landscape11-2.mp4"),
      require("@/assets/video/video_landscape11-3.mp4"),
      require("@/assets/video/video_landscape11-4.mp4"),
      require("@/assets/video/video_landscape11-5.mp4"),
    ],
    describe: "24年11月",
  },
  {
    id: 12,
    videoArray: [
      require("@/assets/video/video_landscape12-1.mp4"),
      require("@/assets/video/video_landscape12-2.mp4"),
      require("@/assets/video/video_landscape12-3.mp4"),
      require("@/assets/video/video_landscape12-4.mp4"),
      require("@/assets/video/video_landscape12-5.mp4"),
      require("@/assets/video/video_landscape12-6.mp4"),
    ],
    describe: "24年12月",
  },
  {
    id: 1,
    videoArray: [
      require("@/assets/video/video_landscape1-1.mp4"),
      require("@/assets/video/video_landscape1-2.mp4"),
      require("@/assets/video/video_landscape1-3.mp4"),
    ],
    describe: "1月",
  },
  {
    id: 2,
    videoArray: [
      require("@/assets/video/video_landscape2-1.mp4"),
      require("@/assets/video/video_landscape2-2.mp4"),
    ],
    describe: "2月",
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_a_anchor_link = _resolveComponent("a-anchor-link")!
  const _component_a_anchor = _resolveComponent("a-anchor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_anchor, {
        direction: "horizontal",
        affix: false,
        onClick: _ctx.onChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slideshowObject.value, (item) => {
            return (_openBlock(), _createBlock(_component_a_anchor_link, {
              key: item.id,
              href: '#videoSchoolTwoSlideshow-' + item.id,
              title: '易天循' + item.describe + '实盘记录'
            }, null, 8, ["href", "title"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(VideoSchoolTwoSlideshow, { "slideshow-object": slideshowObject.value }, null, 8, ["slideshow-object"])
    ])
  ]))
}
}

})