<template>
  <div id="transactionTrainingTwoView">
    <h1>导师直播精彩内容回顾</h1>
    <div class="box">
      <common-panel-component
        :content-title="contentTitle"
        :people-name="peopleName"
        :time="time"
        v-for="i in 6"
        :key="i"
      />
    </div>
    <div class="invitation">
      <span>成为Pyta学员，与顶尖交易名师携手，实现长期稳定的盈利</span>
      <router-link to="/videoList"><u>更多视频</u>→</router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonPanelComponent from "@/components/CommonPanelComponent.vue";

const contentTitle = "课程标题";
const peopleName = "托尼老师";
const time = "2025-2-12 11:20";
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

#transactionTrainingTwoView {
  width: 1200px;
  height: auto;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#transactionTrainingTwoView h1 {
  font-size: 48px;
  line-height: 72px;
  text-align: center;
}

.box {
  height: 692px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 30px;
}

.invitation {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 19px;
}

.invitation span {
  color: #675954;
}

.invitation a {
  color: #de2727;
}
</style>
