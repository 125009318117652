<template>
  <div id="videoSchoolElementTwo">
    <div class="anchorColumn">
      <a-anchor direction="horizontal" :affix="false" @click="onChange">
        <a-anchor-link
          v-for="item in slideshowObject"
          v-bind:key="item.id"
          v-bind:href="'#videoSchoolTwoSlideshow-' + item.id"
          v-bind:title="'易天循' + item.describe + '实盘记录'"
        />
      </a-anchor>
    </div>
    <div class="videSlideshow">
      <video-school-two-slideshow :slideshow-object="slideshowObject" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import VideoSchoolTwoSlideshow from "@/views/videoSchoolView/VideoSchoolTwoSlideshow.vue";

interface slideshowInterface {
  id: number;
  videoArray: Array<any>;
  describe: string;
}

const slideshowObject = ref<slideshowInterface[]>([
  {
    id: 10,
    videoArray: [
      require("@/assets/video/video_landscape10-1.mp4"),
      require("@/assets/video/video_landscape10-2.mp4"),
      require("@/assets/video/video_landscape10-3.mp4"),
    ],
    describe: "24年10月",
  },
  {
    id: 11,
    videoArray: [
      require("@/assets/video/video_landscape11-1.mp4"),
      require("@/assets/video/video_landscape11-2.mp4"),
      require("@/assets/video/video_landscape11-3.mp4"),
      require("@/assets/video/video_landscape11-4.mp4"),
      require("@/assets/video/video_landscape11-5.mp4"),
    ],
    describe: "24年11月",
  },
  {
    id: 12,
    videoArray: [
      require("@/assets/video/video_landscape12-1.mp4"),
      require("@/assets/video/video_landscape12-2.mp4"),
      require("@/assets/video/video_landscape12-3.mp4"),
      require("@/assets/video/video_landscape12-4.mp4"),
      require("@/assets/video/video_landscape12-5.mp4"),
      require("@/assets/video/video_landscape12-6.mp4"),
    ],
    describe: "24年12月",
  },
  {
    id: 1,
    videoArray: [
      require("@/assets/video/video_landscape1-1.mp4"),
      require("@/assets/video/video_landscape1-2.mp4"),
      require("@/assets/video/video_landscape1-3.mp4"),
    ],
    describe: "1月",
  },
  {
    id: 2,
    videoArray: [
      require("@/assets/video/video_landscape2-1.mp4"),
      require("@/assets/video/video_landscape2-2.mp4"),
    ],
    describe: "2月",
  },
]);
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

#videoSchoolElementTwo {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

#videoSchoolElementTwo .anchorColumn {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*粘性定位*/
  position: sticky;
  z-index: 999;
  top: 0;
  background-color: #f7faf8;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

:deep(.ant-anchor-wrapper-horizontal) {
  width: 1200px;
  height: 40px;
}

:deep(.ant-anchor) {
  height: 100%;
}

:deep(.ant-anchor-wrapper-horizontal .ant-anchor .ant-anchor-ink) {
  width: 140px !important;
  height: 4px;
  background-color: #de2727;
}

:deep(.ant-anchor-wrapper .ant-anchor .ant-anchor-link) {
  padding-inline: 25px 25px;
}

:deep(
    .ant-anchor-wrapper-horizontal .ant-anchor .ant-anchor-link:first-of-type
  ) {
  padding-inline: 25px 25px;
}

:deep(.ant-anchor-wrapper .ant-anchor .ant-anchor-link-title) {
  font-size: 16px;
  color: #675954;
}

:deep(
    .ant-anchor-wrapper
      .ant-anchor
      .ant-anchor-link-active
      > .ant-anchor-link-title
  ) {
  color: #675954;
  font-size: 16px;
}

:deep(
    .ant-anchor-wrapper
      .ant-anchor
      .ant-anchor-link-active
      > .ant-anchor-link-title-active
  ) {
  color: #2d211c;
}

.videSlideshow {
  width: 1200px;
  height: auto;
}
</style>
